const styles = ({ breakpoints }) => ({
  content: {
    paddingTop: '30px !important',
    paddingBottom: '30px !important',
    [breakpoints.down('sm')]: {
      paddingBottom: '13vh !important',
    },
  },
  copyrightContainer: {
    maxWidth: 300,
  },
  spacer: {
    width: '100%',
    height: 40,

    [breakpoints.up('sm')]: {
      height: 50,
    },
  },
});

export default styles;
