const styles = ({ breakpoints, typography, content }) => ({
  label: {
    ...content.calc('margin', 'bottom', '/', '2'),
  },
  text: {
    ...content.calc('margin', ['top', 'bottom'], '/', '2'),
  },
  divider: {
    height: typography.pxToRem(3),
    width: typography.pxToRem(82),
    backgroundColor: '#ADBCCF', // TODO replace with theme color
  },
  [breakpoints.up('lg')]: {
    container: {
      width: '50%',
    },
  },
});

export default styles;
