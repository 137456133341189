import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';

import { ContentImage, Card } from 'components';

import styles from './style';

type Props = {
  classes: Object,
  content: Object,
};

const ExtendedTeam = ({ classes, content }: Props) => (
  <ContentImage image={content.image} styleOverides={classes}>
    <Card
      fullWidth
      maxTextHeight={200}
      content={content.card}
      styleOverides={classes}
    />
  </ContentImage>
);

export default compose(
  withCmsContent('extendedTeam'),
  withStyles(styles),
)(ExtendedTeam);
