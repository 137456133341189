import React from 'react';

import { withCmsContent } from '@agile-actors/client/commons/components/cms';

import { Cards } from 'components';

type Props = {
  content: Object,
};

const Industries = ({ content }: Props) => (
  <Cards backgroundColor="#f4f4f4" content={content} />
);

export default withCmsContent('industries')(Industries);
