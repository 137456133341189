import React from 'react';
import classNames from 'classnames';
import MuiMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import { compose } from '@agile-actors/client/commons/utils';
import { withModelProps } from '@agile-actors/client/commons/components/model-props';
import Text from '@agile-actors/client/commons/components/text';
import { Link } from '@agile-actors/client/commons/components/router';
import { withAnchorElement } from '@agile-actors/client/commons/components/withAnchorElement';

import { setTransparent } from 'components/layout/components/header/ui-model';

type Props = {
  id: string,
  classes: Object,
  content: Object,
  transparent: boolean,
  anchorEl?: Object,
  open: boolean,

  setTransparent: Object => void,
  onMouseEnter: () => void,
  onMouseLeave: () => void,
};

const Menu = ({
  id,
  classes,
  content,
  transparent,
  setTransparent,
  open,
  anchorEl,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const isInMenuDropdown = (element, defaultReturnVal) => {
    if (element && element.getAttribute) {
      return element.getAttribute('role') === 'menu';
    }

    return defaultReturnVal;
  };

  const openMenu = event => {
    const element = event.currentTarget;

    if (
      !isInMenuDropdown(element, true) &&
      element?.getAttribute('aria-owns') === id
    ) {
      onMouseEnter(event);
      setTransparent(false);
    }
  };

  const closeMenu = event => {
    if (!isInMenuDropdown(event?.relatedTarget)) {
      onMouseLeave();
    }
  };

  return (
    <div className={classes.menuLinkWrapper}>
      <Text
        variant="body1"
        color={transparent ? 'initial' : 'textPrimary'}
        text={content.title}
        title={content.title}
        aria-owns={id}
        aria-haspopup
        onFocus={() => {}}
        onClick={openMenu}
        onMouseEnter={openMenu}
        onMouseLeave={closeMenu}
        className={classNames(
          classes.menuLinkBase,
          classes.menuLink,
          content.position === 'right' && classes.menuLinkRight,
        )}>
        <KeyboardArrowDown className={classes.menuArrow} />
      </Text>

      <MuiMenu
        id={id}
        role="menu"
        align="center"
        keepMounted
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open && anchorEl?.getAttribute('aria-owns') === id}
        onClose={onMouseLeave}
        MenuListProps={{ onMouseLeave }}
        TransitionComponent={Fade}>
        {content.menuItems.map(item => (
          <MenuItem
            key={item.id}
            role="menuitem"
            onClick={onMouseLeave}
            className={classes.menuItem}>
            <Link
              variant="body1"
              text={item.title}
              to={item.link}
              role="menu"
              className={classNames(classes.menuLink, classes.menuDropdownLink)}
            />
          </MenuItem>
        ))}
      </MuiMenu>
    </div>
  );
};

Menu.defaultProps = {
  anchorEl: null,
};

export default compose(
  withModelProps({ setTransparent }),
  withAnchorElement,
)(Menu);
