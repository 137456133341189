import React from 'react';
import classNames from 'classnames';

import Slider from '@agile-actors/client/commons/components/slider';

import { Card } from 'components';

type Props = {
  content: [],
  border?: boolean,
  classes: Object,
  styleOverides?: Object,
};

const SliderContent = ({
  content,
  border,
  classes,
  styleOverides = {},
  ...rest
}: Props) => (
  <div className={classNames(classes.cards, styleOverides.cards)}>
    <Slider>
      {content.map(card => (
        <div key={`${card.id}`}>
          <Card
            center
            slider
            content={card}
            className={classes.cardSlider}
            styleOverides={styleOverides}
            border={border}
            {...rest}
          />
        </div>
      ))}
    </Slider>
  </div>
);

SliderContent.defaultProps = {
  styleOverides: {},
  border: false,
};

export default SliderContent;
