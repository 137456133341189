import React from 'react';

import { Hero, InformationSecurityPolicy } from './components';

export const InformationSecurity = () => (
  <>
    <Hero />
    <InformationSecurityPolicy />
  </>
);

export default InformationSecurity;
