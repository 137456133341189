const style = ({ typography, breakpoints }) => ({
  container: {
    boxShadow: `0  0 ${typography.pxToRem(40)} 0 rgba(0,0,0,0.3)`,
    backgroundColor: 'white',
    position: 'fixed',
    bottom: 0,
    zIndex: 99999,
    height: 40,

    [breakpoints.up('sm')]: {
      height: 50,
    },
  },
  image: {
    height: 40,

    [breakpoints.up('sm')]: {
      height: 50,
    },
  },
});

export default style;
