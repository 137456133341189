import React from 'react';

import {
  Hero,
  OurPractices,
  CurveBackground,
  Technologies,
  GetInTouch,
} from './components';

export const Practices = () => (
  <>
    <Hero />
    <OurPractices />
    <Technologies />
    <CurveBackground />
    <GetInTouch />
  </>
);

export default Practices;
