const styles = ({ breakpoints, palette, typography }) => ({
  wrapper: {
    backgroundColor: palette.common.white,
    height: '100%',
    width: '100%',
    overflow: 'visible',
  },
  container: {
    zIndex: 2,
    backgroundColor: palette.common.white,
    boxShadow: `0  0 ${typography.pxToRem(40)} 0 rgba(0,0,0,0.3)`,
    width: '75%',
    margin: '0 auto',
    borderRadius: typography.pxToRem(4),
    // ...content.calc('margin', 'top', '*', '-4'),
    marginTop: 'calc(9.5vw * -4)',
  },
  content: {
    paddingLeft: '6%',
    paddingRight: '6%',
    borderRadius: typography.pxToRem(4),
  },
  [breakpoints.up('sm')]: {
    container: {
      // ...content.calc('margin', 'top', '*', '-5'),
      marginTop: 'calc(7vw * -5)',
    },
  },
  [breakpoints.up('lg')]: {
    container: {
      // ...content.calc('margin', 'top', '*', '-6.7'),
      marginTop: 'calc(4.5vw * -6.7)',
    },
  },
});

export default styles;
