import React from 'react';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';

import { compose } from '@agile-actors/client/commons/utils';

import { ContentLayout } from 'components';

import styles from './style';
import {
  Copyright,
  Privacy,
  RegistrationNumber,
  SocialMedia,
} from './components';

type Props = {
  classes: Object,
  width: string,
};

const Footer = ({ classes, width }: Props) => (
  <ContentLayout styleOverides={classes}>
    <Grid container alignItems="center" wrap="wrap-reverse">
      <Grid
        item
        xs={12}
        sm={6}
        container
        alignItems="center"
        justify={width === 'xs' ? 'center' : 'flex-start'}>
        <Grid
          container
          direction="column"
          justify={width === 'xs' ? 'center' : 'flex-end'}
          alignItems="center"
          className={classes.copyrightContainer}>
          <Privacy />
          <Copyright />
          <RegistrationNumber />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        container
        alignItems="center"
        justify={width === 'xs' ? 'center' : 'flex-end'}>
        <SocialMedia />
      </Grid>
    </Grid>
    <div className={classes.spacer} />
  </ContentLayout>
);

export default compose(withStyles(styles), withWidth())(Footer);
