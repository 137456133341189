const styles = ({ content, palette, typography, breakpoints }) => ({
  content: {
    position: 'relative',
    padding: 0,
  },
  imagePane: {
    paddingBottom: typography.pxToRem(30),
    zIndex: 3,
    height: '48vw',

    [breakpoints.up('lg')]: {
      height: '100%',
      padding: 0,
      marginLeft: typography.pxToRem(-30),
    },
  },
  contentPane: {
    [breakpoints.up('lg')]: {
      height: '100%',
    },
  },
  image: {
    width: '65%',

    '& img': {
      objectPosition: 'right !important',
    },
  },
  methodologiesContainer: {
    position: 'relative',
  },
  methodologiesWrapper: {
    width: '100%',
  },
  methodologiesItem: {
    position: 'relative',

    [breakpoints.up('lg')]: {
      '&:nth-child(1)': {
        height: '21vw',
        marginBottom: '15vw',
      },

      '&:nth-child(2)': {
        height: '26vw',
        top: '-8.5vw',
      },

      '&:nth-child(3)': {
        height: '26vw',
        top: '-7vw',
      },

      '&:nth-child(4)': {
        height: '21vw',
        top: '5vw',
      },
    },
    [breakpoints.up('xl')]: {
      '&:nth-child(1)': {
        height: typography.pxToRem(340),
        marginBottom: typography.pxToRem(200),
      },

      '&:nth-child(2)': {
        height: typography.pxToRem(440),
        top: '-5.5vw',
      },

      '&:nth-child(3)': {
        height: typography.pxToRem(440),
        top: '-5vw',
      },

      '&:nth-child(4)': {
        height: typography.pxToRem(350),
        top: 0,
      },
    },
  },
  title: {
    [breakpoints.up('lg')]: {
      ...content.margin('bottom'),
    },
  },
  orderTitle: {
    marginRight: typography.pxToRem(10),

    fontSize: typography.pxToRem(150),
    color: palette.grey[300],
    opacity: 0.7,
  },
  contentTitle: {
    marginBottom: typography.pxToRem(20),
  },
  contentText: {
    [breakpoints.between('sm', 'md')]: {
      width: '75%',
      margin: '0 auto',
    },
  },
  button: {
    ...content.margin('top'),
  },
});

export default styles;
