import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';

import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';
import { Banner } from 'components';

import styles from './style';

type Props = {
  classes: Object,
  content: Object,
  width: string,
};

const Technologies = ({ classes, content, width }: Props) => (
  <Banner
    color="initial"
    content={width === 'lg' || width === 'xl' ? content.large : content.normal}
    styleOverides={classes}
  />
);

export default compose(
  withStyles(styles),
  withCmsContent('technologies'),
  withWidth(),
)(Technologies);
