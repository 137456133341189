import prepareBackgroundSvg from '@agile-actors/client/commons/utils/prepareBackgroundSvg';

import arrow from './images';

const styles = ({ typography, content, breakpoints, palette }) => ({
  outerTitle: {
    ...content.calc('margin', 'bottom', '/', '2'),
    maxWidth: '100%',
  },
  outerSubtitle: {
    ...content.calc('margin', 'bottom', '/', '2'),
    maxWidth: '100%',
  },
  cards: {
    width: '100%',
    ...content.calc('margin', 'top', '/', '2'),

    '& .slick-arrow': {
      zIndex: 100,
    },
    '& .slick-arrow:before': {
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      height: typography.pxToRem(25),
      width: typography.pxToRem(25),
      content: "''",
      opacity: 1,
      display: 'inline-block',
      backgroundColor: palette.common.white,
      borderRadius: '100%',
    },
    '& .slick-next': {
      right: typography.pxToRem(-7),
      marginTop: typography.pxToRem(3),
    },
    '& .slick-next:before': {
      content: `${prepareBackgroundSvg(arrow)}`,
      transform: 'rotate(0deg)',
    },
    '& .slick-prev': {
      left: typography.pxToRem(-11),
    },
    '& .slick-prev:before': {
      content: `${prepareBackgroundSvg(arrow)}`,
      transform: 'rotate(180deg)',
    },
    '& .slick-dots': {
      display: 'none !important',
    },
    '& .slick-track': {
      display: 'flex',
    },
    '& .slick-slide': {
      height: 'auto',
    },
  },
  cardsRow: {
    width: 'auto',
    overflow: 'visible',

    [breakpoints.up('lg')]: {
      marginBottom: 0,
      width: '50%',
      flexWrap: 'nowrap',

      '&:first-child': {
        justifyContent: 'flex-end',
      },
      '&:last-child': {
        justifyContent: 'flex-start',
      },
    },
  },
  cardsSingleRow: {
    [breakpoints.up('sm')]: {
      width: '100% !important',
      justifyContent: 'center !important',
    },
  },
  cardSlider: {
    height: `calc(100% - ${typography.pxToRem(35)})`,
    margin: `0 auto ${typography.pxToRem(35)} !important`,
  },
  button: {
    ...content.calc('margin', 'top', '-', `${typography.pxToRem(50)}`),
  },
  buttonSlider: {
    ...content.calc('margin', 'top', '-', `${typography.pxToRem(20)}`),
  },
});

export default styles;
