import React from 'react';
import { LocationProvider } from '@reach/router';
import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import withStyles from '@material-ui/core/styles/withStyles';

import Meta from '@agile-actors/client/commons/components/meta';
import {
  CmsStyles,
  CmsContext,
} from '@agile-actors/client/commons/components/cms';

import withUISetup from './withUISetup';
import theme from './theme';
import styles from './style';

type WebsiteStylesProps = {
  classes: Object,
  children: HTMLElement | Object | [],
};

const WebsiteStyles = withStyles(styles)(
  ({ classes, children }: WebsiteStylesProps) => (
    <CmsStyles>
      <CssBaseline />
      <div className={classes.container}>{children}</div>
    </CmsStyles>
  ),
);

type WebsiteProps = {
  content?: Object,
  history: Object,
  children: HTMLElement | Object | [],
};

const Website = ({ content, history, children }: WebsiteProps) => {
  return (
    <LocationProvider history={history}>
      <ThemeProvider theme={theme}>
        <CmsContext.Provider value={content}>
          <Meta {...content.meta}>
            {/* eslint-disable react/no-unknown-property */}
            <html lang="en" />

            <link
              media="all"
              rel="preload"
              href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap"
              as="style"
              crossorigin
            />
            <link
              media="all"
              rel="preload"
              href="https://use.typekit.net/ewg8esg.css" // TODO: ask the designers to add "font-display: swap" instead of auto
              as="style"
              crossorigin
            />
            <link
              media="all"
              rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap"
            />
            <link
              media="all"
              rel="stylesheet"
              href="https://use.typekit.net/ewg8esg.css"
            />
          </Meta>
          <WebsiteStyles>{children}</WebsiteStyles>
        </CmsContext.Provider>
      </ThemeProvider>
    </LocationProvider>
  );
};

Website.defaultProps = {
  content: {},
};

export default withUISetup(Website);
