import React from 'react';

import { Hero, Cases, GetInTouch } from './components';

export const Industries = () => (
  <>
    <Hero />
    <Cases />
    <GetInTouch />
  </>
);

export default Industries;
