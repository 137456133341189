import React from 'react';
import Grid from '@material-ui/core/Grid';

import Button from '@agile-actors/client/commons/components/button';

type Props = {
  classes: Object,
  content: Object,
};

const HeaderButton = ({ classes, content }: Props) => (
  <Grid container alignItems="center" style={{ width: 'auto' }}>
    <Button
      variant="contained"
      size="small"
      color="primary"
      to={content.link}
      label={content.title}
      labelClassName={classes.buttonLabel}
      className={classes.button}
    />
  </Grid>
);

export default HeaderButton;
