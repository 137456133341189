const styles = () => ({
  snackbar: {
    bottom: 0,
  },
  content: {
    justifyContent: 'center',
  },
});

export default styles;
