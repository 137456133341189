const styles = ({ content }) => ({
  subtitle: {
    ...content.calc('margin', 'bottom', '/', 2),
  },
  text: {
    '& ul': {
      margin: 0,
    },

    '&:last-child': {
      marginTop: 0,
    },
  },
});

export default styles;
