import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';

import { withModelProps } from '@agile-actors/client/commons/components/model-props';

import { closeModal } from './ui-model';

type Props = {
  fullScreen?: boolean,
  fullWidth?: boolean,
  hasCloseButton?: boolean,
  children?: HTMLElement | Object | [],
  open: boolean,
  disableOnClose: boolean,
  container: object,
  closeModal: () => void,
};

const Transition = props => <Slide direction="up" {...props} />;

const Modal = ({
  fullScreen,
  fullWidth,
  hasCloseButton,
  open,
  children,
  container,
  disableOnClose,
  closeModal,
  ...rest
}: Props) => (
  <Dialog
    {...rest}
    fullScreen={!!fullScreen}
    open={open}
    onClose={() => !disableOnClose && closeModal(container)}
    TransitionComponent={Transition}
    fullWidth={!!fullWidth}>
    {fullScreen ? (
      <AppBar position="static" color="primary">
        <Grid container justify="flex-end">
          <IconButton
            color="secondary"
            onClick={() => closeModal(container)}
            aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Grid>
      </AppBar>
    ) : (
      <Grid container justify="flex-end">
        {hasCloseButton && (
          <IconButton
            color="primary"
            onClick={() => closeModal(container)}
            aria-label="Close">
            <CloseIcon />
          </IconButton>
        )}
      </Grid>
    )}
    {children}
  </Dialog>
);

Modal.defaultProps = {
  fullScreen: false,
  fullWidth: false,
  hasCloseButton: true,
  children: null,
};

export default withModelProps({ closeModal })(Modal);
