import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Done from '@material-ui/icons/Done';
import { v4 } from 'uuid';

import CardImage from '@agile-actors/client/commons/components/image';
import Text from '@agile-actors/client/commons/components/text';
import Button from '@agile-actors/client/commons/components/button';

import styles from './style';

type Props = {
  content: Object,
  center?: boolean,
  border?: boolean,
  slider?: boolean,
  maxTextHeight?: number | string,
  fullWidth?: boolean,
  classes: Object,
  styleOverides?: Object,
};

const CardItem = ({
  content,
  center,
  border,
  slider,
  fullWidth,
  classes,
  maxTextHeight,
  styleOverides = {},
}: Props) => (
  <Grid
    container
    justify="center"
    className={classNames(
      classes.cardContainer,
      slider && classes.slider,
      border && classes.withBorder,
      fullWidth && classes.fullWidth,
      styleOverides.cardContainer,
      slider && styleOverides.slider,
      border && styleOverides.withBorder,
      fullWidth && styleOverides.fullWidth,
    )}>
    <div className={classNames(classes.cardWrapper, styleOverides.cardWrapper)}>
      <Card className={classNames(classes.card, styleOverides.card)}>
        {content.image && (
          <CardImage
            maxWidth={content.imageSize}
            content={content.image}
            className={classNames(classes.image, styleOverides.image)}
          />
        )}
        <CardContent
          className={classNames(
            classes.textWrapper,
            styleOverides.textWrapper,
          )}>
          <Grid container direction="column" justify="center">
            <Text
              text={content.title}
              variant="h4"
              color="initial"
              align={center ? 'center' : 'left'}
              className={classNames(classes.title, styleOverides.title)}
            />
            <Text
              text={content.caption}
              variant="caption"
              color="initial"
              align={center ? 'center' : 'left'}
              className={classNames(classes.caption, styleOverides.caption)}
            />
            <Text
              text={content.content}
              variant="body1"
              align={center ? 'center' : 'left'}
              fullWidth={fullWidth}
              maxTextHeight={maxTextHeight}
              className={classNames(classes.text, styleOverides.text)}
            />
            <Button
              center
              color="primary"
              size="small"
              label={content.buttonLabel}
              labelVariant="caption"
              image={content.buttonImage}
              to={content.buttonLink}
              className={classNames(
                classes.cardButton,
                styleOverides.cardButton,
              )}
            />

            {content.buttonLabel ? (
              <Divider
                className={classNames(classes.divider, styleOverides.divider)}
              />
            ) : null}

            {content.list && (
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                className={classNames(
                  classes.listWrapper,
                  styleOverides.listWrapper,
                )}>
                <Text
                  text={content.list.title}
                  variant="body2"
                  align={center ? 'center' : 'left'}
                  className={classNames(
                    classes.listTitle,
                    styleOverides.listTitle,
                  )}
                />
                <List className={classNames(classes.list, styleOverides.list)}>
                  {content.list.items &&
                    content.list.items.map((item) => (
                      <ListItem
                        disableGutters
                        className={classNames(
                          classes.listItem,
                          styleOverides.listItem,
                        )}
                        key={v4()}>
                        <ListItemIcon>
                          <Done
                            className={classNames(
                              classes.tick,
                              styleOverides.tick,
                            )}
                          />
                        </ListItemIcon>
                        <Text text={item} variant="body2" align="justify" />
                      </ListItem>
                    ))}
                </List>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  </Grid>
);

CardItem.defaultProps = {
  styleOverides: {},
  center: false,
  border: false,
  slider: false,
  fullWidth: false,
  maxTextHeight: '',
};

export default withStyles(styles)(CardItem);
