import React from 'react';
import { Form, Field } from 'react-final-form';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

import CheckBox from '@agile-actors/client/commons/components/form/CheckBox';
import TextField from '@agile-actors/client/commons/components/form/TextField';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { formValidators } from '@agile-actors/client/commons/components/form/utils';
import { withModelProps } from '@agile-actors/client/commons/components/model-props';
import { compose } from '@agile-actors/client/commons/utils';

import { contactFormMessage } from './ui-model';
import styles from './style';

const validators = formValidators;
const {
  composeValidators,
  required,
  email,
  minLength2,
  minLength5,
  maxLength30,
  maxLength120,
} = validators;

type Props = {
  classes: Object,
  content: Object,
};

const ContactForm = ({
  contactFormMessage,
  classes,
  content,
}: Props & formPropTypes) => (
  <Grid
    container
    justify="center"
    direction="column"
    className={classes.formContainer}>
    <Form
      onSubmit={values =>
        contactFormMessage({ values, emailService: content.emailService })
      }
      className={classes.form}
      render={({ handleSubmit, submitting, valid }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column">
            <Field
              name="name"
              label="Name"
              component={TextField}
              validate={composeValidators(minLength2, maxLength30, required)}
              className={classes.formField}
              fullWidth
              required
            />
            <Field
              name="email"
              label="E-mail"
              component={TextField}
              validate={composeValidators(email, required)}
              className={classes.formField}
              fullWidth
              required
            />
            <Field
              name="subject"
              label="Subject"
              component={TextField}
              validate={composeValidators(minLength5, maxLength30, required)}
              className={classes.formField}
              fullWidth
              required
            />
            <Field
              name="message"
              label="Message"
              multiline
              rows={2}
              rowsMax={5}
              component={TextField}
              validate={composeValidators(minLength5, maxLength120, required)}
              className={classes.formField}
              fullWidth
              required
            />
            <Field
              type="checkbox"
              name="consent"
              label={content.consentText}
              component={CheckBox}
              validate={composeValidators(required)}
              formClass={classNames(classes.formControl, classes.checkbox)}
              required
            />
            <Field
              type="checkbox"
              name="newsletter"
              label={content.newsletterText}
              component={CheckBox}
              formClass={classes.formControl}
            />

            <Button
              color="primary"
              type="submit"
              disabled={!valid || submitting}
              className={classes.button}>
              {content.button}
            </Button>
          </Grid>
        </form>
      )}
    />
  </Grid>
);

export default compose(
  withModelProps({ contactFormMessage }),
  withCmsContent('form'),
  withStyles(styles),
)(ContactForm);
