const styles = ({ typography, palette, app, breakpoints }) => ({
  sideBar: {
    width: app.minWidth,
    paddingLeft: typography.pxToRem(40),
    position: 'relative',
    height: '100vh',
  },
  bar: {
    width: '100%',
    maxWidth: app.minWidth,
    height: typography.pxToRem(app.appBarHeight),
    margin: `0 ${typography.pxToRem(-40)}`,
    position: 'fixed',
    zIndex: 1,

    '&:before': {
      position: 'absolute',
      content: '""',
      width: '88%',
      height: typography.pxToRem(60),
      top: 0,
      left: 0,
      backgroundImage: `linear-gradient(bottom, rgba(255,255,255,0.001) 0%, ${palette.common.white} 80%)`,
    },
    '&:after': {
      position: 'absolute',
      content: '""',
      width: '88%',
      height: typography.pxToRem(30),
      top: 0,
      left: 0,
      backgroundImage: `linear-gradient(bottom, rgba(255,255,255,0.001) 0%, ${palette.common.white} 100%)`,
    },
  },
  closeButton: {
    height: typography.pxToRem(60),
    width: typography.pxToRem(60),
    color: palette.primary.main,
  },
  list: {
    width: 'auto',
    height: '100%',
    padding: `${typography.pxToRem(app.appBarHeight)} 0`,

    [breakpoints.up('lg')]: {
      padding: `${typography.pxToRem(app.appBarHeight_lg)} 0`,
    },
  },
  listItem: {
    marginBottom: typography.pxToRem(20),
    width: '87.5%',

    '&:hover': {
      color: palette.primary.main,
    },
  },
  menuList: {
    fontWeight: 500,
    color: palette.grey[400],
    marginBottom: typography.pxToRem(20),
  },
  menuListItem: {
    // fontWeight: 500,
    fontWeight: 600,
    marginBottom: typography.pxToRem(20),
    width: '87.5%',

    '&:hover': {
      color: palette.primary.main,
    },
  },
  divider: {
    width: `calc(100% - ${typography.pxToRem(40)})`,
    marginBottom: typography.pxToRem(20),
    textAlign: 'left',
  },
  arrow: {
    position: 'absolute',
    right: typography.pxToRem(40),
    color: palette.text.primary,
    cursor: 'pointer',
  },
  buttonWrapper: {
    width: 'auto',
    marginBottom: typography.pxToRem(40),
  },
  button: {
    minHeight: typography.pxToRem(35),
  },
});

export default styles;
