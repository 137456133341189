import { pipe } from 'rxjs';
import { startWith, mapTo, filter, map, delay } from 'rxjs/operators';

import { isCurrentPathname } from '@agile-actors/client/commons/utils';
import { combineEpics, ofType } from '@agile-actors/actions-engine/client';

import { locationChange } from 'components/ui-setup/ui-model';

import { scroll as layoutScrollAction } from './actions';
import { toggleSidebar as sidebarToggleAction } from '../components/sidebar/ui-model';
// import { cookiesConsentEpic } from '../components/cookie-consent/aspects';

import { toggle, setTransparent } from '../components/header/ui-model';

const locationChangeToHeaderTransparent = action$ =>
  action$.pipe(
    pipe(startWith(locationChange()), delay(0)),
    ofType(locationChange.type),
    map(() => setTransparent(isCurrentPathname('/', '/tech-professionals'))),
  );

const contentScrollToHeaderTransparent = action$ =>
  action$.pipe(
    ofType(layoutScrollAction.type),
    filter(() => isCurrentPathname('/', '/tech-professionals')),
    map(({ payload }) => setTransparent(payload)),
  );

const headerBurgerToggleToSidebarToggle = action$ =>
  action$.pipe(ofType(toggle.type), mapTo(sidebarToggleAction()));

const epic = combineEpics(
  locationChangeToHeaderTransparent,
  contentScrollToHeaderTransparent,
  headerBurgerToggleToSidebarToggle,
  // cookiesConsentEpic,
);

export default epic;
