import React from 'react';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import { Scrollbars } from 'react-custom-scrollbars';

import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { withModelProps } from '@agile-actors/client/commons/components/model-props';
import { compose } from '@agile-actors/client/commons/utils';

import { transformContent } from 'components/layout/utils';

import { opened, closeSidebar } from './ui-model';
import SidebarMenuList from './components';
import style from './style';

type Props = {
  classes: Object,
  content: Object,
  opened: ?boolean,

  closeSidebar: () => void,
};

const Sidebar = ({ opened, closeSidebar, classes, content }: Props) => {
  const menuData = transformContent(content);

  return (
    <Drawer anchor="right" open={opened} onClose={closeSidebar}>
      <div className={classes.sideBar}>
        <Grid
          container
          alignItems="center"
          justify="flex-end"
          className={classes.bar}>
          <IconButton className={classes.closeButton} onClick={closeSidebar}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Scrollbars universal autoHeight autoHeightMax="100vh">
          <SidebarMenuList
            content={menuData}
            classes={classes}
            close={closeSidebar}
          />
        </Scrollbars>
      </div>
    </Drawer>
  );
};

export default compose(
  withModelProps({ opened, closeSidebar }),
  withCmsContent('menu'),
  withStyles(style),
)(Sidebar);
