const GOOGLE_APIS_URL = 'https://maps.googleapis.com/maps/api/js';
const GoogleMapsKey =
  process.env.NODE_ENV === 'development'
    ? GOOGLE_APIS_URL
    : `${GOOGLE_APIS_URL}?key=AIzaSyCle5_oYEFATwaR3vLpooH9FvOmEvOLP58`;

const mapsConfig = {
  googleMapURL: GoogleMapsKey,
  center: {
    lat: 48.67600575,
    lng: 8.78146745,
  },
  zoom: {
    xs: 1.5,
    sm: 2,
    md: 2.5,
    lg: 2.5,
    xl: 3.5,
  },
  locations: [
    {
      id: 'athens',
      url: `https://www.google.com/maps/place/Agile+Actors/@38.012597,23.786624,20z
              /data=!4m5!3m4!1s0x0:0x31e03f9abecb8932!8m2!3d38.0125961!4d23.7867442?hl=en-US+Greece&z=9`,
      position: {
        lat: 38.012577,
        lng: 23.786774,
      },
    },
  ],
};

export default mapsConfig;
