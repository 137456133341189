const styles = ({ content, typography }) => ({
  title: {
    textAlign: 'center',
    width: '100%',
    ...content.calc('margin', 'bottom', '/', '2'),
  },
  countryContainer: {
    padding: typography.pxToRem(10),
  },
  countryTitle: {
    marginBottom: typography.pxToRem(10),
  },
});

export default styles;
