import React from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import { ContentLayout } from 'components';

import { ContactIntro, ContactForm } from './components';

import styles from './style';

type Props = {
  classes: Object,
};

const ContactDetails = ({ classes }: Props) => (
  <div className={classes.wrapper}>
    <ContentLayout styleOverides={classes}>
      <Grid container alignItems="flex-start" justify="space-between">
        <ContactIntro />
        <ContactForm />
      </Grid>
    </ContentLayout>
  </div>
);

export default withStyles(styles)(ContactDetails);
