import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import { isSafari } from '@agile-actors/client/commons/utils';

import styles from './style';

type Props = {
  children: HTMLElement | Object | [],
  classes: Object,
  className?: string,
  styleOverides?: Object,
  backgroundColor?: string,
  backgroundOnly?: boolean,
};

const ContentLayout = ({
  classes,
  className,
  styleOverides = {},
  backgroundColor,
  backgroundOnly = false,
  children,
}: Props) => (
  <article
    style={backgroundColor ? { backgroundColor } : {}}
    className={classNames(
      classes.container,
      backgroundOnly && classes.backgroundOnly,
      styleOverides.container,
      backgroundOnly && styleOverides.backgroundOnly,
      isSafari() && classes.safari,
      className,
    )}>
    <Grid
      container
      justify="center"
      className={classNames(classes.content, styleOverides.content)}>
      {children}
    </Grid>
  </article>
);

ContentLayout.defaultProps = {
  styleOverides: {},
  className: '',
  backgroundColor: '',
  backgroundOnly: false,
};

export default withStyles(styles)(ContentLayout);
