const styles = ({ typography }) => ({
  button: {
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.2)',
    },
  },
  title: {
    height: typography.pxToRem(42),
  },
  text: {
    maxWidth: typography.pxToRem(800),
  },
});

export default styles;
