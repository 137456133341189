import React from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';

import { withTabsIndex } from '@agile-actors/client/commons/components/withTabsIndex';

import TabLabel from './TabLabel';
import TabContent from './TabContent';

type Props = {
  classes: Object,
  color: string,
  styleOverides?: Object,
  content: [],
  tabIndex: number,

  onChangeTab: () => void,
};

const NormalContent = ({
  classes,
  color,
  styleOverides,
  content,
  onChangeTab,
  tabIndex,
}: Props) => (
  <Grid
    container
    alignItems="flex-start"
    justify="center"
    className={classNames(classes.tabs, styleOverides.tabs)}>
    <Grid
      item
      xs={12}
      md={5}
      container
      alignItems="flex-start"
      justify="flex-start"
      className={classNames(
        classes.tabOuterContainer,
        styleOverides.tabOuterContainer,
      )}>
      <TabLabel
        content={content}
        classes={classes}
        styleOverides={styleOverides}
        color={color}
        tabIndex={tabIndex}
        onChangeTab={onChangeTab}
        desktop
      />
    </Grid>
    <Grid
      item
      xs={12}
      md={7}
      container
      alignItems="flex-start"
      justify="flex-end"
      className={classNames(
        classes.tabItemOuterContainer,
        styleOverides.tabItemOuterContainer,
      )}>
      <TabContent
        content={content}
        classes={classes}
        styleOverides={styleOverides}
        tabIndex={tabIndex}
        desktop
      />
    </Grid>
  </Grid>
);

NormalContent.defaultProps = {
  styleOverides: {},
};

export default withTabsIndex(NormalContent);
