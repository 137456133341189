import React from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';

import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';

import { ContentLayout, Card } from 'components';

import styles from './style';

type Props = {
  classes: Object,
  content: Object,
  width: string,
};

const Cases = ({ classes, content, width }: Props) => (
  <Grid container justify="center" alignItems="flex-start">
    {content.cards.map((card, index) => {
      const greyInSmall = width !== 'lg' && width !== 'xl' && index % 2 !== 0;
      const greyInLarge =
        (width === 'lg' || width === 'xl') && (index === 1 || index === 2);

      return (
        <Grid
          item
          xs={12}
          lg={6}
          key={card.id}
          className={classNames(
            greyInLarge && classes.greyBackground,
            greyInSmall && classes.greyBackground,
          )}>
          <ContentLayout
            styleOverides={{
              content: classNames(
                classes[`content_${index % 2 !== 0 ? 'right' : 'left'}`],
                classes.container,
              ),
            }}>
            <Card fullWidth content={card} styleOverides={classes} />
          </ContentLayout>
        </Grid>
      );
    })}
  </Grid>
);

export default compose(
  withWidth(),
  withCmsContent('cases'),
  withStyles(styles),
)(Cases);
