const style = () => ({
  link: {
    position: 'relative',
    width: '100%',
    height: '100%',

    '&:focus': {
      outline: 0,
    },
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',

    '& img': {
      objectPosition: 'left !important',
    },
  },
  coloful: {
    opacity: 0,

    '&.show': {
      opacity: 1,
    },
  },
});

export default style;
