import {
  epic as actionsEpic,
  combineEpics,
} from '@agile-actors/actions-engine/client';

import { epic as layoutEpic } from 'components/layout/ui-model';

const epics = combineEpics(actionsEpic, layoutEpic);

export default epics;
