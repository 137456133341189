import React from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';

import { Link } from '@agile-actors/client/commons/components/router';

import { Menu, Button } from './components';

type Props = {
  classes: Object,
  content: [],
  transparent?: boolean,
};

const HeaderMenu = ({ classes, content, transparent }: Props) => (
  <Grid container wrap="nowrap" style={{ width: 'auto', height: '100%' }}>
    {content.map(item => {
      switch (item.type) {
        case 'menu':
          return (
            <Menu
              key={item.id}
              id={item.id}
              classes={classes}
              content={item}
              transparent={transparent}
            />
          );
        case 'link':
          return (
            <Link
              variant="body1"
              color={transparent ? 'initial' : 'textPrimary'}
              to={item.link}
              text={item.title}
              title={item.title}
              className={classNames(
                classes.menuLinkBase,
                classes.menuLink,
                item.position === 'right' && classes.menuLinkRight,
              )}
              key={item.id}
            />
          );
        case 'button':
          return <Button key={item.id} classes={classes} content={item} />;
        default:
          return null;
      }
    })}
  </Grid>
);

HeaderMenu.defaultProps = {
  transparent: false,
};

export default HeaderMenu;
