import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';

import Image from '@agile-actors/client/commons/components/image';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';

import { Logo } from 'components';

import styles from './style';

type Props = {
  classes: Object,
  content: Object,
  width: string,
};

const isMobileView = width =>
  width === 'xs' || width === 'sm' || width === 'md';

const IE = ({ classes, content, width }: Props) => {
  const IEContent = () => (
    <div className={classes.textWrapper}>
      <Typography
        variant="h1"
        align={isMobileView(width) ? 'center' : 'left'}
        color="initial"
        className={classes.title}>
        {content.title}
      </Typography>
      <Typography
        variant="h6"
        align={isMobileView(width) ? 'center' : 'left'}
        color="initial"
        className={classes.text}
        dangerouslySetInnerHTML={{ __html: content.text }}
      />
      <div className={classes.buttonContainer}>
        <Button
          size={isMobileView(width) ? 'small' : 'medium'}
          color="primary"
          onClick={() => global.open(content.buttonLink)}
          className={classes.button}
          classes={{ sizeSmall: classes.buttonSmall }}>
          <Typography
            variant="button"
            color="primary"
            className={classes.buttonLabel}>
            {content.buttonLabel}
          </Typography>
        </Button>
      </div>
    </div>
  );

  return (
    <div className={classes.container}>
      <CssBaseline />
      <div className={classes.headerWrapper}>
        <div className={classes.header}>
          <Logo className={classes.logo} />
        </div>
      </div>
      <div className={classes.content}>
        <Image
          background
          content={content.image}
          className={classes.backgroundImage}
        />
        <IEContent />
      </div>
    </div>
  );
};

export default compose(
  withWidth(),
  withCmsContent('ie'),
  withStyles(styles),
)(IE);
