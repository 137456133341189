import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';

import { Cards } from 'components';

import styles from './style';

type Prop = {
  classes: Object,
  content: Object,
};

const Leadership = ({ classes, content }: Prop) => (
  <Cards border content={content} styleOverides={classes} />
);

export default compose(
  withCmsContent('leadership'),
  withStyles(styles, { index: 1 }),
)(Leadership);
