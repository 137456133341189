import React from 'react';

import { withModelActivation } from '@agile-actors/client/commons/components/model-activation';

import { Hero, Clocks, CurveBackground, ContactDetails } from './components';
import { contactFormEpic } from './components/contact-details/components/contact-form/ui-model';

const Contact = () => (
  <>
    <Hero />
    <ContactDetails />
    <Clocks />
    <CurveBackground />
  </>
);

export default withModelActivation({
  epics: contactFormEpic,
})(Contact);
