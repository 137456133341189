import React from 'react';

import { Hero, Intro, Statements } from './components';

export const Privacy = () => (
  <>
    <Hero />
    <Intro />
    <Statements />
  </>
);

export default Privacy;
