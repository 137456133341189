import React from 'react';

import { withCmsContent } from '@agile-actors/client/commons/components/cms';

import { Banner } from 'components';

type Props = {
  content: Object,
};

const Hero = ({ content }: Props) => (
  <Banner hero centerText setBackgroundSize content={content} />
);

export default withCmsContent('hero')(Hero);
