import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';

import { Modal } from 'components';
import { openModal } from 'components/modal/ui-model';

import Text from '@agile-actors/client/commons/components/text';
import Image from '@agile-actors/client/commons/components/image';
import { withToggleValue } from '@agile-actors/client/commons/components/withToggleValue';
import { withModelProps } from '@agile-actors/client/commons/components/model-props';
import { compose } from '@agile-actors/client/commons/utils';

import { open } from './ui-model';
import { InfoBoxGoogleMap } from './components';
import mapsConfig from './config';
import styles from './style';

const modalContainer = { component: 'map' };

type Props = {
  open: boolean,
  openModal: modalState => void,
  toggleValue: boolean,
  onToggle: () => {},
  content: Object,
  classes: Object,
  width: string,
};

const ContactMap = ({
  toggleValue,
  onToggle,
  content,
  classes,
  open,
  openModal,
  width,
}: Props) => {
  const node = <div className={classes.mapElement} />;
  const zoom = mapsConfig.zoom[width] || 2;

  return (
    <article className={classes.container}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        onClick={() => {
          openModal(modalContainer);
        }}
        role="button"
        onKeyPress={() => {
          openModal(modalContainer);
        }}
        aria-label={content.label}
        className={classes.expandContainer}>
        <Image content={content.image} className={classes.imageContainer} />
        <Text
          text={content.label}
          variant="h5"
          color="textSecondary"
          align="center"
          className={classes.label}
        />
      </Grid>
      <InfoBoxGoogleMap
        classes={classes}
        containerElement={node}
        mapElement={node}
        content={content}
        config={mapsConfig}
        loadingElement={node}
        googleMapURL={mapsConfig.googleMapURL}
        showInfo={toggleValue}
        onMarkerClick={onToggle}
        zoom={zoom}
      />
      <Modal
        fullScreen
        className={classes.modal}
        open={open}
        container={modalContainer}>
        <InfoBoxGoogleMap
          classes={classes}
          containerElement={node}
          mapElement={node}
          content={content}
          config={mapsConfig}
          loadingElement={node}
          googleMapURL={mapsConfig.googleMapURL}
          showInfo={toggleValue}
          onMarkerClick={onToggle}
          zoom={zoom + 1.5}
        />
      </Modal>
    </article>
  );
};

export default compose(
  withModelProps({ open, openModal }),
  withWidth(),
  withToggleValue,
  withStyles(styles),
)(ContactMap);
