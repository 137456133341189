import React from 'react';

import { Hero, Intro, Statements } from './components';

export const PrivacySurveillance = () => (
  <>
    <Hero />
    <Intro />
    <Statements />
  </>
);

export default PrivacySurveillance;
