import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';

import { withModelProps } from '@agile-actors/client/commons/components/model-props';
import { compose, isCmsRoute } from '@agile-actors/client/commons/utils';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';

import Logo from 'components/logo';
import { ContentLayout } from 'components';
import { transformContent } from 'components/layout/utils';

import { HeaderMenu } from './components';
import { toggle, transparent } from './ui-model';
import styles from './style';

type Props = {
  classes: Object,
  content: Object,
  toggle: Object => void,
  transparent: ?boolean,
};

export const Header = ({ classes, content, toggle, transparent }: Props) => {
  const menuData = transformContent(content);

  return (
    <Grid
      container
      className={classNames(
        classes.appBarContainer,
        transparent && classes.transparent,
      )}>
      <AppBar position="absolute" classes={{ root: classes.appBar }}>
        <ContentLayout styleOverides={classes}>
          <Grid container alignItems="center" className={classes.wrapper}>
            <Grid item xs={7} md={3} container justify="flex-start">
              <div className={classes.logo}>
                <Logo colorful={!transparent} />
              </div>
            </Grid>

            {!isCmsRoute() ? (
              <>
                <Hidden mdDown>
                  <Grid
                    item
                    xs={6}
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.wrapper}>
                    <HeaderMenu
                      classes={classes}
                      transparent={transparent}
                      content={menuData.filter(
                        item => item.position === 'center',
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    container
                    justify="flex-end"
                    alignItems="center"
                    className={classes.wrapper}>
                    <HeaderMenu
                      right
                      classes={classes}
                      transparent={transparent}
                      content={menuData.filter(
                        item => item.position === 'right',
                      )}
                    />
                  </Grid>
                </Hidden>
                <Hidden lgUp>
                  <Grid
                    item
                    xs={5}
                    md={9}
                    container
                    justify="flex-end"
                    alignItems="center"
                    className={classes.wrapper}>
                    <Button
                      disableRipple
                      className={classes.button}
                      onClick={toggle}
                      aria-label="Open Sidebar Button">
                      <MenuIcon color={transparent ? 'inherit' : 'primary'} />
                    </Button>
                  </Grid>
                </Hidden>
              </>
            ) : null}
          </Grid>
        </ContentLayout>
      </AppBar>
    </Grid>
  );
};

export default compose(
  withModelProps({ toggle, transparent }),
  withCmsContent('menu'),
  withStyles(styles),
)(Header);
