const styles = ({ typography, palette, app, breakpoints }) => ({
  container: {
    width: '100%',
    height: '100vh',
  },
  headerWrapper: {
    width: '100%',
    backgroundColor: palette.common.white,
    boxShadow: `0 0 ${typography.pxToRem(4)} 0 rgba(0,0,0,0.15)`,
  },
  header: {
    width: '100%',
    height: `${typography.pxToRem(app.appBarHeight)}`,
    boxSizing: 'border-box',

    [breakpoints.up('lg')]: {
      height: `${typography.pxToRem(app.appBarHeight_lg)}`,
    },
  },
  logo: {
    height: '50%',
    position: 'relative',
    top: 0,
    transform: 'translateY(-50%)',
    marginLeft: `calc(3% + ${typography.pxToRem(20)})`,

    [breakpoints.up('lg')]: {
      marginLeft: typography.pxToRem(40),
    },
  },
  content: {
    position: 'relative',
    height: `calc(100vh - ${typography.pxToRem(app.appBarHeight)})`,
    maxHeight: '95vh',

    [breakpoints.up('lg')]: {
      height: `calc(100vh - ${typography.pxToRem(app.appBarHeight_lg)})`,
    },
  },
  backgroundImage: {
    '& img': {
      right: '0 !important',
      objectFit: 'fill !important',
      objectPosition: 'bottom right !important',
      width: '100% !important',
      top: 'unset !important',
      left: 'unset !important',
      bottom: '0 !important',
    },

    [breakpoints.up('sm')]: {
      '& img': {
        width: '50% !important',
      },
    },

    [breakpoints.up('lg')]: {
      '& img': {
        width: '70% !important',
      },
    },
  },
  textWrapper: {
    paddingTop: 0,
    paddingBottom: 0,
    position: 'relative',
    width: '90%',
    margin: '0 auto',
    top: '15%',
    transform: 'translateY(-15%)',

    [breakpoints.up('lg')]: {
      top: '30%',
      transform: 'translateY(-30%)',
    },
  },
  title: {
    marginBottom: typography.pxToRem(20),
    fontWeight: 700,

    [breakpoints.up('lg')]: {
      width: '50%',
    },
  },
  text: {
    marginBottom: typography.pxToRem(50),

    [breakpoints.up('lg')]: {
      fontSize: typography.pxToRem(30),
    },
  },
  buttonContainer: {
    position: 'relative',
    textAlign: 'center',

    [breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  buttonSmall: {
    minWidth: typography.pxToRem(170),
  },
  button: {
    marginBottom: typography.pxToRem(20),
    padding: `${typography.pxToRem(13)} ${typography.pxToRem(26)}`,
  },
  buttonLabel: {
    fontSize: typography.pxToRem(14),
    fontWeight: '700',

    [breakpoints.up('lg')]: {
      fontSize: typography.pxToRem(20),
    },
  },
});

export default styles;
