import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import ErrorOutlineOutlined from '@material-ui/icons/ErrorOutlineOutlined';

import Text from '@agile-actors/client/commons/components/text';
import Button from '@agile-actors/client/commons/components/button';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { withModelProps } from '@agile-actors/client/commons/components/model-props';
import {
  removeCookies,
  isCmsRoute,
  isBrowser,
  addCookie,
  compose,
} from '@agile-actors/client/commons/utils';

import { ContentLayout, Modal } from 'components';

import { closeModal } from 'components/modal/ui-model';

import { open } from './ui-model';
import styles from './style';

type Props = {
  classes: Object,
  content: Object,
  closeModal: modalState => void,
  open: boolean,
};

const modalContainer = { component: 'consent' };

const CookieConsent = ({ classes, content, closeModal, open }: Props) => {
  const rejectCookies = () => {
    removeCookies();
    window.open('https://medium.com/agileactors', '_self');
  };

  const acceptCookies = () => {
    addCookie('cookieConsent', 'yes');
    closeModal(modalContainer);
  };

  return isBrowser() ? (
    <div>
      <Modal
        fullWidth
        disablePortal
        hasCloseButton={false}
        classes={{
          container: classes.rootContainer,
          paper: classes.rootPaper,
        }}
        open={open}
        disableOnClose
        container={modalContainer}>
        <ContentLayout styleOverides={classes}>
          <Grid container wrap="nowrap">
            <ErrorOutlineOutlined
              color="primary"
              fontSize="large"
              className={classes.icon}
            />
            <Text text={content.message} color="initial" variant="body2" />
          </Grid>
          <Grid
            container
            justify="flex-end"
            className={classes.buttonsContainer}>
            <Button
              size="small"
              className={classes.button}
              color="primary"
              label={content.buttons.reject}
              labelVariant="caption"
              gridClassName={classes.buttonWrapper}
              onClick={() => {
                !isCmsRoute() && rejectCookies();
              }}
            />
            <Button
              size="small"
              variant="contained"
              className={classes.button}
              color="primary"
              label={content.buttons.accept}
              labelVariant="caption"
              gridClassName={classes.buttonWrapper}
              onClick={() => {
                !isCmsRoute() && acceptCookies();
              }}
            />
          </Grid>
        </ContentLayout>
      </Modal>
    </div>
  ) : null;
};

export default compose(
  withModelProps({ open, closeModal }),
  withCmsContent('cookieConsent'),
  withStyles(styles),
)(CookieConsent);
