import React from 'react';
import classNames from 'classnames';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import Text from '@agile-actors/client/commons/components/text';
import Button from '@agile-actors/client/commons/components/button';

import { ContentLayout } from 'components';

import { MobileContent, NormalContent } from './components';
import styles from './style';

type Props = {
  content: Object,
  classes: Object,
  color?: string,
  styleOverides?: Object,
  backgroundColor?: string,
};

const Tabs = ({
  content,
  classes,
  styleOverides = {},
  color = '',
  backgroundColor = '',
}: Props) => (
  <ContentLayout
    backgroundColor={backgroundColor}
    styleOverides={styleOverides}>
    <Grid container direction="column" justify="center" alignItems="center">
      <Text
        text={content.title}
        variant="h3"
        color={color || 'initial'}
        align="left"
        className={classNames(classes.outerTitle, styleOverides.outerTitle)}
      />
      <Text
        text={content.subtitle}
        variant="h5"
        color={color || 'initial'}
        align="center"
        className={classNames(
          classes.outerSubtitle,
          styleOverides.outerSubtitle,
        )}
      />

      <Hidden mdDown>
        <NormalContent
          content={content.tabs}
          classes={classes}
          styleOverides={styleOverides}
          color={color || 'primary'}
        />
      </Hidden>
      <Hidden lgUp>
        <MobileContent
          content={content.tabs}
          classes={classes}
          styleOverides={styleOverides}
          color={color || 'primary'}
        />
      </Hidden>

      <Button
        size="large"
        color={color || 'primary'}
        className={classNames(classes.button, styleOverides.button)}
        label={content.buttonLabel}
        to={content.buttonLink}
        labelClassName={classNames(
          classes.buttonLabel,
          styleOverides.buttonLabel,
        )}
      />
    </Grid>
  </ContentLayout>
);

Tabs.defaultProps = {
  styleOverides: {},
  color: '',
  backgroundColor: '',
};

export default withStyles(styles)(Tabs);
