import React from 'react';

import {
  Hero,
  Vision,
  Values,
  CurveBackground,
  GetInTouch,
} from './components';

export const VisionAndValues = () => (
  <>
    <Hero />
    <Vision />
    <Values />
    <CurveBackground />
    <GetInTouch />
  </>
);

export default VisionAndValues;
