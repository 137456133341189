const styles = ({ breakpoints, typography }) => ({
  container: {
    [breakpoints.up('md')]: {
      minHeight: `100vh`,
    },
  },
  content: {
    [breakpoints.up('md')]: {
      minHeight: `100vh`,
    },
  },
  title: {
    [breakpoints.up('sm')]: {
      maxWidth: typography.pxToRem(800),
    },
    [breakpoints.up('md')]: {
      maxWidth: typography.pxToRem(1000),
    },
    [breakpoints.up('lg')]: {
      maxWidth: typography.pxToRem(1200),
    },
  },
  button: {
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.2)',
    },
  },
  [breakpoints.up('sm')]: {
    button: {
      marginTop: '4vw',
    },
    text: {
      maxWidth: typography.pxToRem(450),
    },
  },
  [breakpoints.up('md')]: {
    text: {
      maxWidth: typography.pxToRem(760),
    },
  },
});

export default styles;
