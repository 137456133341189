import React from 'react';

import {
  Hero,
  Unlock,
  AgileMethodology,
  Practice,
  CurrentOpenings,
} from './components';

const TechProfessionals = () => (
  <>
    <Hero />
    <Unlock />
    <AgileMethodology />
    <Practice />
    <CurrentOpenings />
  </>
);

export default TechProfessionals;
