const styles = ({ typography, breakpoints }) => ({
  image: {
    paddingTop: typography.pxToRem(30),
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 1,

    [breakpoints.up('lg')]: {
      minHeight: typography.pxToRem(90),
    },
  },
});

export default styles;
