import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import { setTransparent } from './actions';

const headerDefaultState = {
  transparent: true,
};

const headerActionsReducers = {
  [setTransparent.type]: (header, { payload }) =>
    update(header, {
      transparent: { $set: payload },
    }),
};

const reducer = handleActions(headerActionsReducers, headerDefaultState);

export default reducer;
