import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { Banner } from 'components';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';

import styles from './style';

type Props = {
  classes: Object,
  content: Object,
  theme: Object,
};

const Vision = ({ classes, content, theme }: Props) => (
  <Banner
    color="initial"
    backgroundColor={'#f4f4f4' || theme.palette.common.faintBlack}
    content={content}
    styleOverides={classes}
  />
);

export default compose(
  withStyles(styles, { withTheme: true }),
  withCmsContent('vision'),
)(Vision);
