const styles = ({ typography, content, breakpoints, palette }) => ({
  outerTitle: {
    ...content.calc('margin', 'bottom', '/', '2'),
    width: '100%',
    maxWidth: '100%',
  },
  outerSubtitle: {
    ...content.calc('margin', 'bottom', '/', '2'),
    maxWidth: '100%',
  },
  tabContainer: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: typography.pxToRem(35),

    '& + span': {
      backgroundColor: 'transparent',
    },
  },
  scrollingButton: {
    flex: '0',
    display: 'flex !important',
    color: palette.text.primary,
  },
  tabs: {
    ...content.calc('margin', 'top', '/', `${typography.pxToRem(2)}`),
  },
  tabOuterContainer: {
    width: '40%',
  },
  tabItemOuterContainer: {
    width: '60%',
  },
  rootTabs: {
    width: '100%',
  },
  rootTab: {
    maxWidth: '100% !important',
    minHeight: typography.pxToRem(44),
    height: '100%',
    flexShrink: 0,
  },
  tabWrapper: {
    alignItems: 'flex-start',
  },
  tabLabel: {
    fontWeight: 600,
  },
  tabLabelActive: {
    [breakpoints.up('lg')]: {
      textDecoration: 'underline',
    },
  },
  tabLabelPrimary: {
    '&:hover': {
      color: palette.primary.main,
    },
  },
  contentLabel: {
    marginBottom: typography.pxToRem(15),
  },
  listItemWrapper: {
    paddingTop: typography.pxToRem(5),
    paddingBottom: typography.pxToRem(5),
  },
  listTitle: {
    marginTop: typography.pxToRem(10),
  },
  button: {
    ...content.margin('top'),
  },
  [breakpoints.up('lg')]: {
    tabContainer: {
      marginTop: 0,
    },
  },
});

export default styles;
