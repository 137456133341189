/* global google */

import React from 'react';
import Paper from '@material-ui/core/Paper';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from 'react-google-maps/lib';

import Text from '@agile-actors/client/commons/components/text';
import { compose } from '@agile-actors/client/commons/utils';

type Props = {
  classes: Object,
  content: Object,
  showInfo: boolean,
  onMarkerClick: () => void,
  config: Object,
  zoom: number,
};

let activeMarker = 0;

const InfoBoxGoogleMap = ({
  classes,
  content,
  showInfo,
  onMarkerClick,
  config,
  zoom,
}: Props) => {
  const handleOpen = selectedMarker => {
    activeMarker = selectedMarker;
    onMarkerClick();
  };
  return (
    <GoogleMap
      defaultZoom={zoom}
      center={config.center}
      options={{ scrollwheel: false, fullscreenControl: false }}>
      {config.locations.map((location, index) => (
        <Marker
          key={location.id}
          position={location.position}
          onClick={() => handleOpen(index)}
          icon={content.icon}>
          {activeMarker === index && showInfo && (
            <InfoBox
              options={{
                pixelOffset: new google.maps.Size(-120, 0),
                enableEventPropagation: true,
                closeBoxURL: '',
              }}>
              <Paper
                className={classes.infoBoxContent}
                onClick={() => {
                  global.open(location.url);
                }}>
                <Text
                  text={content.title}
                  variant="body1"
                  align="center"
                  color="primary"
                />
                <Text
                  text={content.locations[index]}
                  variant="body2"
                  align="center"
                />
              </Paper>
            </InfoBox>
          )}
        </Marker>
      ))}
    </GoogleMap>
  );
};

export default compose(withScriptjs, withGoogleMap)(InfoBoxGoogleMap);
