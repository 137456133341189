import React from 'react';

import { Tabs } from 'components';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';

type Props = {
  content: Object,
};

const Values = ({ content }: Props) => <Tabs content={content} />;

export default withCmsContent('values')(Values);
