const styles = ({ typography, palette, app, breakpoints }) => ({
  transparent: {
    '& $appBar': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      transition: 'box-shadow .1s, background-color .1s',
    },
  },
  appBarContainer: {
    height: `${typography.pxToRem(app.appBarHeight)}`,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 10,
  },
  appBar: {
    height: '100%',
    backgroundColor: palette.common.white,
    transition: 'box-shadow .5s, background-color .5s',
  },
  container: {
    backgroundColor: 'transparent !important',
  },
  content: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',

    [breakpoints.up('lg')]: {
      paddingLeft: '3% !important',
      paddingRight: '3% !important',
    },
  },
  wrapper: {
    height: '100%',
  },
  bar: {
    flex: 1,
  },
  logo: {
    height: 45,
    width: 246,

    '& > div': {
      justifyContent: 'flex-start !important',
    },
  },
  button: {
    minHeight: typography.pxToRem(35),
    marginLeft: typography.pxToRem(10),
    border: 'none',
    minWidth: 0,
  },
  buttonLabel: {
    fontFamily: 'chalet-new-york-sixty, sans-serif',
    fontWeight: 700,
    fontSize: typography.pxToRem(14),
    minWidth: 0,
  },
  [breakpoints.up('lg')]: {
    appBarContainer: {
      zIndex: 2000,
      height: `${typography.pxToRem(app.appBarHeight_lg)}`,
    },
    container: {
      height: `${typography.pxToRem(app.appBarHeight_lg)}`,
    },

    menuLinkWrapper: {
      minWidth: typography.pxToRem(140),
    },
    menuLinkBase: {
      cursor: 'default',
      height: '100%',
      padding: `0 ${typography.pxToRem(10)}`,
      display: 'inline-block',
      textAlign: 'center',

      '&:hover': {
        textShadow: `${typography.pxToRem(0.3)} 0 0`,
      },
    },
    menuItem: {
      height: 'auto',
      padding: 0,
    },
    menuLink: {
      height: `calc(100% + ${typography.pxToRem(10)})`,
      fontFamily: 'chalet-new-york-sixty, sans-serif',
      padding: `
    calc(
      ${typography.pxToRem(app.appBarHeight_lg / 2)}
      - ${typography.pxToRem(11)}
    )
    ${typography.pxToRem(16)}
    calc(
      ${typography.pxToRem(app.appBarHeight_lg / 2)}
      - ${typography.pxToRem(11)}
    )
    0
    `,
    },
    menuDropdownLink: {
      height: '100%',
      width: `calc(100% + ${typography.pxToRem(32)})`,
      padding: `${typography.pxToRem(12)} ${typography.pxToRem(16)}`,
      textShadow: 'none',
    },
    menuLinkRight: {
      fontFamily: 'chalet-london-sixty, sans-serif',
      height: '100%',
      textShadow: 'none !important',
    },
    menuArrow: {
      float: 'right',
      width: typography.pxToRem(24),
      height: typography.pxToRem(24),
    },
  },
});

export default styles;
