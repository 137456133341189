import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { Scrollbars } from 'react-custom-scrollbars';

import { withModelProps } from '@agile-actors/client/commons/components/model-props';
import withScrollToTop from '@agile-actors/client/commons/components/withScrollToTop';
import {
  compose,
  changeMonitor,
  isSafari,
  // isCmsRoute,
} from '@agile-actors/client/commons/utils';

// import { Footer, Header, Sidebar, Snackbar, CookieConsent } from './components';
import { Footer, Header, Sidebar, Snackbar, EspaBanner } from './components';
// import { consent } from './components/cookie-consent/ui-model';

import { scroll } from './ui-model';
import style from './style';

type Props = {
  classes: Object,
  children: HTMLElement | Object | [],
  scroll: () => void,
  refScrollToTopElement: (element: Object) => void,
  // consent: boolean,
};

const Layout = ({
  classes,
  children,
  refScrollToTopElement,
  // consent,
  scroll,
}: Props) => {
  const isScrollTopChanged = changeMonitor(true);

  const onScroll = ({ scrollTop }) => {
    if (isScrollTopChanged(scrollTop <= 0)) {
      scroll(scrollTop <= 0);
    }
  };

  return (
    <section className={classes.container}>
      <Header />
      <Sidebar />
      <Scrollbars
        universal
        autoHeight
        autoHeightMax="100vh"
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        onScrollFrame={onScroll}
        ref={refScrollToTopElement}
        className={classNames(
          classes.scrollContainer,
          isSafari() && classes.safari,
        )}>
        <div className={classes.content}>
          {children}
          <Footer />
        </div>
      </Scrollbars>
      <Snackbar />
      <EspaBanner />
      {/* {!isCmsRoute() && !consent && <CookieConsent />} */}
    </section>
  );
};

export default compose(
  // connectProps(consent, scroll),
  withModelProps({ scroll }),
  withStyles(style),
  withScrollToTop,
)(Layout);
