import React from 'react';
import withTheme from '@material-ui/core/styles/withTheme';

import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';

import { Banner } from 'components';

type Props = {
  theme: Object,
  content: Object,
};

const Life = ({ content, theme: { palette } }: Props) => (
  <Banner
    backgroundColor={'#f4f4f4' || palette.common.faintBlack}
    content={content}
    color="initial"
    buttonColor="primary"
    centerButton
  />
);

export default compose(withTheme, withCmsContent('life'))(Life);
