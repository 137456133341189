import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { Cards } from 'components';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';

import styles from './style';

type Prop = {
  classes: Object,
  content: Object,
};

const Practice = ({ classes, content }: Prop) => (
  <Cards
    backgroundColor="#F6F6F6"
    border
    arrow
    content={content}
    styleOverides={classes}
  />
);

export default compose(
  withStyles(styles),
  withCmsContent('practice'),
)(Practice);
