import React from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';

import CardsRow from './CardsRow';

type Props = {
  content: [],
  classes: Object,
  width: string,
  border?: boolean,
  styleOverides?: Object,
};

const NormalContent = ({
  classes,
  border,
  content,
  width,
  styleOverides = {},
  ...rest
}: Props) => {
  const firstRow = content.slice(0, 2);
  const secondRow = content.slice(2, 4);
  const oddCards = content.length % 2 !== 0;

  return (
    <Grid
      container
      justify="center"
      className={classNames(classes.cards, styleOverides.cards)}>
      {oddCards ? (
        <CardsRow
          singleRow
          cardsRow={content}
          classes={classes}
          styleOverides={styleOverides}
          border={border}
          direction={
            oddCards && width !== 'xs' && width !== 'sm' ? 'row' : 'column'
          }
          {...rest}
        />
      ) : (
        <Grid container justify="center">
          <CardsRow
            cardsRow={firstRow}
            classes={classes}
            styleOverides={styleOverides}
            border={border}
            {...rest}
          />
          <CardsRow
            cardsRow={secondRow}
            classes={classes}
            styleOverides={styleOverides}
            border={border}
            {...rest}
          />
        </Grid>
      )}
    </Grid>
  );
};

NormalContent.defaultProps = {
  styleOverides: {},
  border: false,
};

export default NormalContent;
