import React from 'react';
import classNames from 'classnames';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Text from '@agile-actors/client/commons/components/text';

type Props = {
  tabIndex: number,
  content: [Object],
  classes: Object,
  styleOverides?: Object,
  color: string,
  desktop?: boolean,

  onChangeTab: () => void,
};

const TabLabel = ({
  tabIndex,
  content,
  classes,
  styleOverides,
  color,
  desktop = false,
  onChangeTab,
}: Props) => (
  <Tabs
    variant={desktop ? 'standard' : 'scrollable'}
    className={classNames(classes.rootTabs, styleOverides.rootTabs)}
    classes={
      desktop
        ? {
            flexContainer: classNames(
              classes.tabContainer,
              styleOverides.tabContainer,
            ),
          }
        : {
            scrollButtonsDesktop: classNames(
              classes.scrollingButton,
              styleOverides.scrollingButton,
            ),
          }
    }
    value={tabIndex}
    indicatorColor={desktop ? 'secondary' : color}
    onChange={onChangeTab}>
    {content.map((tab, index) => (
      <Tab
        className={classNames(classes.rootTab, styleOverides.rootTab)}
        classes={
          desktop
            ? {
                wrapper: classNames(
                  classes.tabWrapper,
                  styleOverides.tabWrapper,
                ),
              }
            : {}
        }
        label={
          <Text
            align="left"
            text={`${index < 9 ? '0' : ''}${index + 1}.  / ${tab.label}`}
            color={tabIndex === index ? color : 'initial'}
            variant={desktop ? 'h5' : 'body2'}
            className={classNames(
              color === 'primary' && classes.tabLabelPrimary,
              classes.tabLabel,
              styleOverides.tabLabel,
              tabIndex === index ? classes.tabLabelActive : '',
            )}
          />
        }
        key={tab.id}
        disableRipple
      />
    ))}
  </Tabs>
);

TabLabel.defaultProps = {
  styleOverides: {},
  desktop: false,
};

export default TabLabel;
