import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';

import Image from '@agile-actors/client/commons/components/image';
import withVisibilitySensor from '@agile-actors/client/commons/components/withVisibilitySensor';
import { compose } from '@agile-actors/client/commons/utils';

import { Banner } from 'components';

import curve from './images';
import styles from './style';

type Props = {
  classes: Object,
  width: string,
  backgroundImg: string | Object | [],
  styleOverides?: Object,
  isVisible: boolean,
};

const CurveBackground = ({
  classes,
  width,
  backgroundImg,
  styleOverides = {},
  isVisible,
  ...rest
}: Props) => (
  <div
    className={classNames(
      classes.curveBackgroundWrapper,
      isVisible && classes.isVisible,
      styleOverides.curveBackgroundWrapper,
    )}>
    <Image
      content={curve}
      className={classNames(classes.curve, styleOverides.curve)}
    />
    <div
      className={classNames(
        classes.curvedImage,
        styleOverides.curvedImage,
        width !== 'xs' && !isVisible && classes.curvedImageFixed,
        width !== 'xs' && !isVisible && styleOverides.curvedImageFsixed,
      )}>
      <Banner
        backgroundOnly
        content={{ background: { image: backgroundImg } }}
        styleOverides={styleOverides}
        {...rest}
      />
    </div>
  </div>
);

CurveBackground.defaultProps = {
  styleOverides: {},
};

export default compose(
  withStyles(styles),
  withWidth(),
  withVisibilitySensor({ bottomOnly: true }),
)(CurveBackground);
