import createTheme from '@material-ui/core/styles/createTheme';
import merge from 'lodash/merge';

import palette from './palette';
import typography from './typography';

const muiTheme = createTheme({
  props: {
    MuiWithWidth: {
      initialWidth: 'xs', // Breakpoint being globally set 🌎!
    },
  },
  typography: {
    useNextVariants: true,
  },
  palette,
});

muiTheme.typography = merge(
  muiTheme.typography,
  typography(muiTheme.typography),
);

// Overrides
muiTheme.overrides = {
  MuiDialog: {
    root: {
      zIndex: 3000,
    },
  },
  MuiSnackbarContent: {
    root: {
      backgroundColor: muiTheme.palette.primary.main,
    },
  },
  MuiFormLabel: {
    root: {
      color: muiTheme.palette.text.primary,
    },
    focused: {
      color: muiTheme.palette.primary.main,
    },
  },
  MuiButton: {
    root: {
      boxShadow: 'none !important',
      zIndex: 3,
      borderRadius: muiTheme.typography.pxToRem(99),
      backgroundColor: 'transparent',
      border: `${muiTheme.typography.pxToRem(2)} solid ${
        muiTheme.palette.common.white
      }`,
      color: muiTheme.palette.common.white,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    textPrimary: {
      border: `${muiTheme.typography.pxToRem(2)} solid ${
        muiTheme.palette.primary.main
      }`,
      color: muiTheme.palette.primary.main,
    },
    textSecondary: {
      border: `${muiTheme.typography.pxToRem(2)} solid ${
        muiTheme.palette.secondary.main
      }`,
      color: muiTheme.palette.secondary.main,
    },
    containedPrimary: {
      border: 'none !important',
    },
    disabled: {
      border: `${muiTheme.typography.pxToRem(1)} solid rgba(0, 0, 0, 0.26)`,
    },
    sizeSmall: {
      padding: `
        ${muiTheme.typography.pxToRem(10)} ${muiTheme.typography.pxToRem(20)}
      `,
    },
    sizeLarge: {
      padding: `
        ${muiTheme.typography.pxToRem(20)} ${muiTheme.typography.pxToRem(70)}
      `,
    },
  },
  MuiTypography: {
    colorTextSecondary: {
      color: muiTheme.palette.common.white,
    },
    colorInherit: {
      color: 'inherit',
    },
    colorSecondary: {
      color: muiTheme.palette.secondary.main,
    },
    colorPrimary: {
      color: muiTheme.palette.primary.main,
    },
    h1: {
      [muiTheme.breakpoints.down('sm')]: {
        fontSize: muiTheme.typography.pxToRem(46),
      },
      [muiTheme.breakpoints.down('xs')]: {
        fontSize: muiTheme.typography.pxToRem(32),
      },
    },
    h2: {
      [muiTheme.breakpoints.down('sm')]: {
        fontSize: muiTheme.typography.pxToRem(28),
      },
    },
    h3: {
      [muiTheme.breakpoints.down('sm')]: {
        fontSize: muiTheme.typography.pxToRem(28),
      },
    },
    h4: {
      [muiTheme.breakpoints.down('sm')]: {
        fontSize: muiTheme.typography.pxToRem(20),
      },
    },
    h5: {
      [muiTheme.breakpoints.down('sm')]: {
        fontSize: muiTheme.typography.pxToRem(18),
      },
      [muiTheme.breakpoints.down('xs')]: {
        fontSize: muiTheme.typography.pxToRem(16),
      },
    },
    h6: {
      [muiTheme.breakpoints.down('sm')]: {
        fontSize: muiTheme.typography.pxToRem(16),
      },
    },
    subtitle1: {
      [muiTheme.breakpoints.down('sm')]: {
        fontSize: muiTheme.typography.pxToRem(14),
      },
    },
    body1: {
      [muiTheme.breakpoints.down('sm')]: {
        fontSize: muiTheme.typography.pxToRem(14),
      },
    },
    body2: {
      [muiTheme.breakpoints.down('sm')]: {
        fontSize: muiTheme.typography.pxToRem(12),
      },
    },
  },
};

export default muiTheme;
