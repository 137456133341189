const styles = ({ app, breakpoints, typography }) => ({
  container: {
    width: '100%',
    height: typography.pxToRem(180),
    marginTop: typography.pxToRem(15),
    display: 'flex',
  },
  expandContainer: {
    height: '100%',
    background:
      'linear-gradient(32.67deg, #00B0CB 0%, #58C4CF 65.53%, #86CDD4 100%)', // TODO remove colors use theme
    width: '60%',
    marginTop: 0,
    cursor: 'pointer',
  },
  imageContainer: {
    width: typography.pxToRem(28),
    height: typography.pxToRem(34),
    minHeight: 0,
  },
  label: {
    fontWeight: 500,
    marginTop: typography.pxToRem(15),
    width: '75%',
  },
  mapElement: {
    width: '100%',
    height: '100%',
  },
  infoBoxContent: {
    width: '95%',
    minWidth: typography.pxToRem(170),
    margin: '0 auto',
    padding: typography.pxToRem(5),
    opacity: '0.8',
    cursor: 'pointer',
  },
  modal: {
    top: `${app.appBarHeight}px !important`,

    '& header': {
      height: typography.pxToRem(48),
      justifyContent: 'center',
      paddingLeft: typography.pxToRem(15),
    },
    '& button': {
      height: typography.pxToRem(40),
      width: typography.pxToRem(40),
      padding: '0',

      '& > span': {
        height: typography.pxToRem(30),
        width: typography.pxToRem(30),

        '& > svg': {
          height: '100%',
          width: '100%',
        },
      },
    },

    [breakpoints.up('lg')]: {
      top: `${app.appBarHeight_lg}px !important`,
    },
  },
  [breakpoints.up('sm')]: {
    infoBoxContent: {
      width: typography.pxToRem(240),
      padding: typography.pxToRem(10),
    },
    container: {
      height: typography.pxToRem(240),
    },
    label: {
      width: '50%',
    },
  },
  [breakpoints.up('lg')]: {
    container: {
      height: typography.pxToRem(280),
    },
    expandContainer: {
      width: typography.pxToRem(280),
      marginLeft: '-17vw',
    },
  },
  [breakpoints.up('xl')]: {
    container: {
      height: typography.pxToRem(320),
    },
    expandContainer: {
      width: typography.pxToRem(326),
      marginLeft: typography.pxToRem(-326),
    },
  },
});

export default styles;
