const styles = ({ typography, breakpoints }) => ({
  outerTitle: {
    zIndex: 1,
  },
  title: {
    whiteSpace: 'nowrap',
  },
  text: {
    [breakpoints.up('lg')]: {
      minHeight: typography.pxToRem(144),
    },
    [breakpoints.up(1464)]: {
      minHeight: typography.pxToRem(120),
    },
  },
});

export default styles;
