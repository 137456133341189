const styles = ({ typography, app, content, palette }) => ({
  safari: {},
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    maxWidth: app.maxWidth,
    backgroundColor: palette.common.white,
    margin: '0 auto',
    '&$safari': {
      WebkitTransform: 'translate3d(0,0,0)',
      transformStyle: 'preserve-3d',
    },
  },
  content: {
    height: '100%',
    position: 'relative',
    backgroundColor: 'inherit',
    ...content.padding(),
  },
  backgroundOnly: {
    height: '35vw',
    minHeight: typography.pxToRem(200),
    maxHeight: typography.pxToRem(600),
    width: '100%',
    position: 'relative',
    maxWidth: app.maxWidth,
    margin: '0 auto',

    '& $content': {
      padding: 0,
    },
  },
});

export default styles;
