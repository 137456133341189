import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

import Image from '@agile-actors/client/commons/components/image';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';
import { withModelProps } from '@agile-actors/client/commons/components/model-props';

import {
  Link,
  routerSelector,
} from '@agile-actors/client/commons/components/router';

import styles from './style';

type Props = {
  className?: string,
  colorful?: boolean,
  classes: Object,
  content: Object,
  routerSelector: Object,
};

const Logo = ({
  className,
  colorful,
  classes,
  content,
  routerSelector,
  ...rest
}: Props) => (
  <Link
    to={routerSelector?.location?.pathname !== '/' ? '/' : '#'}
    className={classes.link}>
    <Image
      content={content.white}
      className={classNames(classes.image, className)}
      placeholderWidth={246}
      step={246}
      size={246}
      maxWidth={246}
      {...rest}
    />
    <Image
      content={content.colorful}
      className={classNames(
        classes.image,
        classes.coloful,
        className,
        colorful && 'show',
      )}
      placeholderWidth={246}
      step={246}
      size={246}
      maxWidth={246}
      {...rest}
    />
  </Link>
);

Logo.defaultProps = {
  className: '',
  colorful: true,
};

export default compose(
  withStyles(styles),
  withCmsContent('logo'),
  withModelProps({ routerSelector }),
)(Logo);
