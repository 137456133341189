import React from 'react';
import classNames from 'classnames';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { v4 } from 'uuid';

import Text from '@agile-actors/client/commons/components/text';

type Props = {
  tabIndex: number,
  content: [Object],
  classes: Object,
  styleOverides?: Object,
};

const TabContent = ({ tabIndex, content, classes, styleOverides }: Props) => (
  <div>
    {content.map(
      (content, index) =>
        tabIndex === index && (
          <div
            key={content.id}
            className={classNames(
              classes.tabContainer,
              styleOverides.tabContainer,
            )}>
            <Text
              text={content.title}
              variant="h4"
              align="left"
              className={classNames(
                classes.contentLabel,
                styleOverides.contentLabel,
              )}
            />
            <Text text={content.text} variant="subtitle1" align="justify" />
            {content.list && (
              <div>
                {content.list.title && (
                  <Text
                    text={content.list.title}
                    variant="subtitle1"
                    align="justify"
                    className={classNames(
                      classes.listTitle,
                      styleOverides.listTitle,
                    )}
                  />
                )}
                <List>
                  {content.list.items &&
                    content.list.items.map(item => (
                      <ListItem
                        key={v4()}
                        className={classNames(
                          classes.listItemWrapper,
                          styleOverides.listItemWrapper,
                        )}>
                        <ListItemIcon>
                          <ChevronRight />
                        </ListItemIcon>
                        <Text
                          text={item}
                          variant="subtitle1"
                          align="justify"
                          className={classNames(
                            classes.listItem,
                            styleOverides.listItem,
                          )}
                        />
                      </ListItem>
                    ))}
                </List>
              </div>
            )}
            <Text
              text={content.extraText}
              variant="subtitle1"
              align="justify"
            />
          </div>
        ),
    )}
  </div>
);

TabContent.defaultProps = {
  styleOverides: {},
};

export default TabContent;
