import React from 'react';

import { withCmsContent } from '@agile-actors/client/commons/components/cms';

import { CurveBackground as CurveBackgroundTemplate } from 'components';

type Props = {
  content: Object,
};

const CurveBackground = ({ content }: Props) => (
  <CurveBackgroundTemplate infinite={false} backgroundImg={content.images} />
);

export default withCmsContent('slider')(CurveBackground);
