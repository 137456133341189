import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import { consentGiven } from './actions';

const cookiesDefaultState = {
  consent: false,
};

const cookiesActionsReducers = {
  [consentGiven.type]: (state, { payload }) =>
    update(state, {
      consent: { $set: payload },
    }),
};

const cookiesReducer = handleActions(
  cookiesActionsReducers,
  cookiesDefaultState,
);

export default cookiesReducer;
