import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { compose } from '@agile-actors/client/commons/utils';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';

import { Cards } from 'components';

import styles from './style';

type Props = {
  classes: Object,
  content: Object,
};

const BusinessCases = ({ classes, content }: Props) => (
  <Cards border slider content={content} styleOverides={classes} />
);

export default compose(
  withStyles(styles),
  withCmsContent('models'),
)(BusinessCases);
