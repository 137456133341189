const styles = ({ typography }) => ({
  mainLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'inherit',
  },
  privacyLink: {
    textDecoration: 'none',
  },
  modal: {
    '& button': {
      height: typography.pxToRem(50),
      width: typography.pxToRem(50),

      '& > span': {
        height: typography.pxToRem(30),
        width: typography.pxToRem(30),

        '& > svg': {
          height: '100%',
          width: '100%',
        },
      },
    },
  },
  list: {
    padding: '0 35px 50px 20px',
  },
});

export default styles;
