const style = () => ({
  container: {
    left: 0,
    top: 0,
    width: '100%',
    overflow: 'hidden',
  },
  safari: {},
  scrollContainer: {
    '& > div:first-child': {
      overflowY: 'auto !important',
      overflowX: 'hidden !important',
    },
    '&$safari  > div:first-child': {
      transformStyle: 'preserve-3d',
    },
  },
  content: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
  },
});

export default style;
