const styles = ({ content, typography }) => ({
  container: {
    backgroundColor: '#f4f4f4',
  },
  outerSubtitle: {
    textAlign: 'left',
    ...content.calc('margin', 'bottom', '/', '1'),
  },
  tabLabel: {
    fontSize: typography.pxToRem(17),
  },
  contentLabel: {
    fontSize: typography.pxToRem(25),
  },
});

export default styles;
