import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import { ContentLayout } from 'components';

import { ImagePane } from './components';
import styles from './style';

type Props = {
  classes: Object,
  styleOverides?: Object,
  contentPaneCols?: number,
  image: any,
  imageOntop?: boolean,
  reverse?: boolean,
  watermark?: boolean,
  backgroundColor?: string,
  children: HTMLElement | Object | [],
};

const ContentImage = ({
  classes,
  styleOverides = {},
  contentPaneCols = 6,
  image,
  imageOntop,
  reverse,
  watermark,
  backgroundColor,
  children,
}: Props) => (
  <ContentLayout
    backgroundColor={backgroundColor}
    styleOverides={styleOverides}>
    <Grid
      container
      direction={reverse ? 'row-reverse' : 'row'}
      wrap={imageOntop ? 'wrap-reverse' : 'wrap'}
      className={classNames(classes.panesWrapper, styleOverides.panesWrapper)}
      justify="center"
      alignItems="center">
      {/* Content pane (on the left in 'reverse' */}
      <Grid
        item
        xs={12}
        lg={contentPaneCols}
        container
        alignItems="center"
        className={classNames(classes.contentPane, styleOverides.contentPane)}>
        {children}
      </Grid>

      {/* Image pane (in the right in 'reverse' */}
      <Grid
        item
        xs={12}
        lg={12 - contentPaneCols}
        className={classNames(
          classes.imagePane,
          watermark && classes.imagePaneWatermark,
          styleOverides.imagePane,
        )}>
        <ImagePane
          content={image}
          classes={classes}
          styleOverides={styleOverides}
          watermark={watermark}
          reverse={reverse}
        />
      </Grid>
    </Grid>
  </ContentLayout>
);

ContentImage.defaultProps = {
  styleOverides: {},
  contentPaneCols: 6,
  reverse: false,
  imageOntop: false,
  watermark: false,
  backgroundColor: '',
};

export default withStyles(styles)(ContentImage);
