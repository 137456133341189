import React from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import { Link } from '@agile-actors/client/commons/components/router';

import Menu from './Menu';
import Button from './Button';

type Props = {
  classes: Object,
  content: [],
  close: () => void,
};

const SidebarMenuList = ({ classes, content, close }: Props) => (
  <Grid container direction="column" wrap="nowrap" className={classes.list}>
    {content.map(item => {
      switch (item.type) {
        case 'menu':
          return (
            <Menu
              key={item.id}
              classes={classes}
              content={item}
              close={close}
            />
          );
        case 'link':
          return (
            <Grid container direction="column" onClick={close} key={item.id}>
              <Link
                variant="body1"
                color="initial"
                to={item.link}
                text={item.title}
                className={classNames(
                  classes.listItem,
                  item.position === 'center' && classes.menuListItem,
                )}
              />
              {item.position === 'center' ? (
                <KeyboardArrowRight className={classes.arrow} />
              ) : null}
              {item.position === 'center' ? (
                <Divider className={classes.divider} />
              ) : null}
            </Grid>
          );
        case 'button':
          return (
            <Button
              key={item.id}
              classes={classes}
              content={item}
              close={close}
            />
          );
        default:
          return null;
      }
    })}
  </Grid>
);

export default SidebarMenuList;
