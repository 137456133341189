import React from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import Pdf from './ΑΑ_POIOTIKOS_WEB_GR.pdf';
import espaBannerImage from './images';
import styles from './style';

type Props = {
  classes: Object,
};

const EspaBanner = ({ classes }: Props) => (
  <Grid
    container
    justify="center"
    alignItems="center"
    className={classes.container}>
    <a href={Pdf} target="_blank" rel="noreferrer">
      <img src={espaBannerImage} alt="Espa Banner" className={classes.image} />
    </a>
  </Grid>
);

export default withStyles(styles)(EspaBanner);
