const styles = ({ palette, content, breakpoints }) => ({
  container: {
    backgroundColor: palette.common.white,
  },
  content_left: {
    [breakpoints.up('lg')]: {
      ...content.calc('padding', 'right', '/', 2),
    },
  },
  content_right: {
    [breakpoints.up('lg')]: {
      ...content.calc('padding', 'left', '/', 2),
    },
  },
  textWrapper: {
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`,
  },
  title: {
    textAlign: 'center',
  },
  greyBackground: {
    '& $container': {
      backgroundColor: `${palette.grey[100]} !important`,
    },
  },
  image: {
    width: '180px !important',
    margin: 'auto',

    [breakpoints.up('xl')]: {
      width: '220px !important',
    },
  },
});

export default styles;
