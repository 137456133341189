import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { compose } from '@agile-actors/client/commons/utils';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';

import { Banner } from 'components';

import styles from './style';

type Props = {
  classes: Object,
  content: Object,
};

const GetInTouch = ({ classes, content }: Props) => (
  <Banner
    centerText
    centerButton
    setBackgroundSize
    content={content}
    backgroundColor="#B1BFD2"
    styleOverides={classes}
  />
);

export default compose(
  withStyles(styles),
  withCmsContent('getInTouch'),
)(GetInTouch);
