const styles = ({ typography, app, content, breakpoints }) => ({
  backgroundImg: {
    '& img': {
      objectFit: 'cover !important',
    },
  },
  spacer: {
    width: '100%',
    height: `${typography.pxToRem(app.appBarHeight)}`,
  },
  title: {
    ...content.calc('margin', 'bottom', '/', '2'),
    maxWidth: '100%',
    zIndex: 2,
  },
  subtitle: {
    zIndex: 2,
    maxWidth: '100%',
  },
  text: {
    ...content.calc('margin', 'top', '/', '2'),
    maxWidth: '100%',
    zIndex: 2,
  },
  button: {
    ...content.margin('top'),
    zIndex: 2,
  },
  line: {
    width: typography.pxToRem(80),
    height: typography.pxToRem(3),
    backgroundColor: 'white',
    opacity: 0.35,
    borderRadius: typography.pxToRem(3),
    zIndex: 2,
  },
  [breakpoints.up('lg')]: {
    spacer: {
      height: `${typography.pxToRem(app.appBarHeight_lg)}`,
    },
  },
});

export default styles;
