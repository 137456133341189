const styles = ({ typography, breakpoints }) => ({
  label: {
    marginBottom: '3vw',
  },
  socialWrapper: {
    width: typography.pxToRem(400),
    marginBottom: '5vw',
    textAlign: 'center',
  },
  socialContainer: {
    width: typography.pxToRem(40),
    height: typography.pxToRem(40),
    marginRight: typography.pxToRem(15),
    marginLeft: typography.pxToRem(15),

    '&:hover': {
      opacity: 0.7,
    },
  },
  socialLink: {
    textDecoration: 'none',
  },
  [breakpoints.up('sm')]: {
    label: {
      marginBottom: 0,
    },
    socialWrapper: {
      marginBottom: 0,
      textAlign: 'left',
    },
  },
});

export default styles;
