import React from 'react';
import moment from 'moment-timezone';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';

import Text from '@agile-actors/client/commons/components/text';
import Clock from '@agile-actors/client/commons/components/clock';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';

import { ContentLayout } from 'components';

type Props = {
  width: string,
  content: Object,
};

const getOffsetString = timezone => {
  const utcOffset = moment()
    .tz(timezone)
    .utcOffset();

  return `${utcOffset / 60}`;
};

const Clocks = ({ width, content }: Props) => (
  <ContentLayout>
    <Grid
      container
      direction={width === 'xs' ? 'column' : 'row'}
      justify="center"
      alignItems="center"
      spacing={5}>
      {content.map(item => (
        <Grid
          item
          xs={12 / content.length}
          container
          direction="column"
          justify="center"
          alignItems="center"
          wrap="nowrap"
          key={item.town}>
          <Clock gmtOffset={getOffsetString(item.timezone)} />
          <Text text={item.town} variant="h3" align="center" />
          <Text text={item.address} variant="subtitle1" align="center" />
          <Text text={item.telephone} variant="subtitle1" align="center" />
        </Grid>
      ))}
    </Grid>
  </ContentLayout>
);

export default compose(withWidth(), withCmsContent('clocks'))(Clocks);
