const styles = ({ breakpoints, typography }) => ({
  [breakpoints.up('sm')]: {
    content: {
      paddingTop: '5vw',
      paddingBottom: '5vw',
    },
  },
  icon: {
    paddingRight: typography.pxToRem(10),
  },
  buttonsContainer: {
    marginTop: typography.pxToRem(30),
  },
  buttonWrapper: {
    width: typography.pxToRem(120),
    alignItems: 'flex-end',
  },
  button: {
    minHeight: typography.pxToRem(35),
  },
  rootContainer: {
    alignItems: 'flex-end !important',
  },
  rootPaper: {
    position: 'relative',
    margin: '0 !important',
    maxWidth: '100% !important',
  },
});

export default styles;
