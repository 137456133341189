import { combineReducers } from 'redux';

// import { cookiesReducer as cookies } from '../components/cookie-consent/ui-model';
import { snackbarReducer as snackbar } from '../components/snackbar/ui-model';
import { reducer as header } from '../components/header/ui-model';
import { reducer as sidebar } from '../components/sidebar/ui-model';

const reducer = combineReducers({
  header,
  sidebar,
  snackbar,
  // cookies,
});

export default reducer;
