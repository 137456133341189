import React from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';

import Image from '@agile-actors/client/commons/components/image';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';

import styles from './style';

type Props = {
  classes: Object,
  content: Object,
  width: string,
};

const SocialMedia = ({ classes, content, width }: Props) => (
  <Grid
    container
    justify={width === 'xs' ? 'center' : 'flex-end'}
    alignItems="center"
    direction={width === 'xs' ? 'column' : 'row'}
    className={classes.socialWrapper}>
    <Typography variant="subtitle1" className={classes.label}>
      {content.label}
    </Typography>
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ width: 'auto' }}>
      {content.media.map(social => (
        // <span className={classes.socialContainer} key={social.id}>
        //   <a href={social.url} className={classes.socialLink} target="_black">
        //     {social.Img}
        //   </a>
        // </span>
        <a
          href={social.url}
          className={classes.socialLink}
          aria-label={`Visit our ${social.id} page`}
          target="_black"
          key={social.id}>
          <Image content={social.image} className={classes.socialContainer} />
        </a>
      ))}
    </Grid>
  </Grid>
);

export default compose(
  withWidth(),
  withCmsContent('socialMedia'),
  withStyles(styles),
)(SocialMedia);
