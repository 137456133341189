const styles = ({ breakpoints, typography, content }) => ({
  formContainer: {
    ...content.margin('top'),
  },
  form: {
    width: '100%',
  },
  formField: {
    marginBottom: typography.pxToRem(50),
  },
  formControl: {
    maxWidth: '100%',
  },
  checkbox: {
    marginBottom: 15,
  },
  button: {
    height: typography.pxToRem(65),
    minWidth: typography.pxToRem(212),
    marginTop: typography.pxToRem(50),
  },
  [breakpoints.up('lg')]: {
    formContainer: {
      width: '45%',
      height: '100%',
      paddingLeft: '5%',
      marginTop: '0 !important',
    },
    button: {
      alignSelf: 'center',
    },
  },
});

export default styles;
