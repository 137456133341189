import { of } from 'rxjs';
import { switchMap, mapTo, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import { ofType } from '@agile-actors/actions-engine/client';

import { openSnackbar } from 'components/ui-setup/ui-model';

import { contactFormMessage } from './actions';

export const MESSAGE_SUCCESS = 'Message sent. Thank you!';
export const MESSAGE_FAIL =
  'Something went wrong... Message not sent, please retry';

export const contactFormEpic = action$ =>
  action$.pipe(
    ofType(contactFormMessage.type),
    switchMap(({ payload }) =>
      ajax({
        url: 'https://api.emailjs.com/api/v1.0/email/send',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          user_id: payload?.emailService?.userId,
          service_id: payload?.emailService?.serviceId,
          template_id: payload?.emailService?.templateId,
          template_params: payload.values,
        },
      }).pipe(
        mapTo(openSnackbar({ message: MESSAGE_SUCCESS })),
        catchError(() => of(openSnackbar({ message: MESSAGE_FAIL }))),
      ),
    ),
  );

export default contactFormEpic;
