import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';

import { Tabs } from 'components';

import styles from './style';

type Props = {
  classes: Object,
  content: Object,
};

const Statements = ({ classes, content }: Props) => (
  <Tabs styleOverides={classes} content={content} />
);

export default compose(
  withStyles(styles),
  withCmsContent('statements'),
)(Statements);
