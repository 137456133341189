import React from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import Text from '@agile-actors/client/commons/components/text';
import { Link } from '@agile-actors/client/commons/components/router';

type Props = {
  classes: Object,
  content: Object,
  close: () => void,
};

const Menu = ({ content, classes, close }: Props) => (
  <div>
    <Text
      variant="h6"
      color="initial"
      text={content.title}
      className={classes.menuList}
    />
    {content.menuItems.map(item => (
      <Grid container onClick={close} key={item.id}>
        <Link
          variant="body1"
          text={item.title}
          to={item.link}
          className={classes.menuListItem}
        />
      </Grid>
    ))}
    <Divider className={classes.divider} />
  </div>
);

export default Menu;
