import { injectReducer } from '@agile-actors/client/commons/store';
import { initializeEpics } from '@agile-actors/actions-engine/client';

import { epics, reducer } from 'components/ui-setup/ui-model';

const storeConfig = store => {
  injectReducer(store, reducer);
  initializeEpics(store, epics);
};

export default storeConfig;
