import React from 'react';
import classNames from 'classnames';

import Media from '@agile-actors/client/commons/components/media';

import WatermarkImage from './WatermarkImage';

type Props = {
  content: string | Object,
  classes: Object,
  styleOverides: Object,
  watermark: boolean,
  reverse: boolean,
};

const ImagePane = ({
  content,
  classes = {},
  styleOverides = {},
  watermark,
  reverse,
}: Props) => {
  if (watermark) {
    return (
      <WatermarkImage
        content={content}
        className={classNames(
          classes.image,
          classes.imageBackground,
          styleOverides.image,
          styleOverides.imageBackground,
        )}
      />
    );
  }
  return (
    <Media
      content={{ image: content }}
      className={classNames(
        classes.image,
        reverse && classes.imageReverse,
        styleOverides.image,
        reverse && styleOverides.imageReverse,
      )}
    />
  );
};

export default ImagePane;
