import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';

import { Banner } from 'components';

import styles from './style';

type Props = {
  classes: Object,
  content: Object,
};

const CurrentOpenings = ({ classes, content }: Props) => (
  <Banner
    centerText
    centerButton
    setBackgroundSize
    content={content}
    backgroundColor="#1578E4"
    styleOverides={classes}
  />
);

export default compose(
  withStyles(styles),
  withCmsContent('currentOpenings'),
)(CurrentOpenings);
