import React from 'react';

import {
  Hero,
  ExtendedTeam,
  ManagedTeam,
  Coaching,
  GetInTouch,
} from './components';

export const Models = () => (
  <>
    <Hero />
    <ExtendedTeam />
    <ManagedTeam />
    <Coaching />
    <GetInTouch />
  </>
);

export default Models;
