const content = {
  link: 'Company Policies',
  links: [
    {
      title: 'Data Protection Statement for job applicants',
      url: '/privacy',
    },
    {
      title: 'Data Protection Statement for web users',
      url: '/privacy-web',
    },
    {
      title: 'Data Protection Statement for video surveillance',
      url: '/privacy-surveillance',
    },
    {
      title: 'Information Security Policy ',
      url: '/information-security-policy',
    },
    {
      title: 'Whistleblowing Regulation',
      url: '/whistleblowing-regulation',
    },
  ],
};

export default content;
