import React from 'react';

import { withCmsContent } from '@agile-actors/client/commons/components/cms';

import { Banner } from 'components';

type Props = {
  content: Object,
};

const Hero = ({ content }: Props) => (
  <Banner
    hero
    centerText
    setBackgroundSize
    backgroundColor="#7AC6CE"
    content={content}
  />
);

export default withCmsContent('hero')(Hero);
