import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import Text from '@agile-actors/client/commons/components/text';
import Image from '@agile-actors/client/commons/components/image';
import Button from '@agile-actors/client/commons/components/button';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';

import { ContentLayout } from 'components';

import styles from './style';

type Props = {
  classes: Object,
  content: Object,
};

const Unlock = ({ classes, content }: Props) => (
  <ContentLayout styleOverides={classes}>
    <Grid container direction="column" alignItems="center" justify="center">
      <Text
        text={content.title}
        variant="h2"
        color="textSecondary"
        align="center"
        className={classes.title}
      />
      <Text
        text={content.text}
        variant="h6"
        color="textSecondary"
        align="center"
        className={classes.text}
      />
      <Button
        size="large"
        color="initial"
        center
        label={content.buttonLabel}
        to={content.buttonLink}
        className={classes.button}
      />
    </Grid>
    <Image
      background
      content={content.background}
      className={classes.background}
    />
  </ContentLayout>
);

export default compose(withStyles(styles), withCmsContent('unlock'))(Unlock);
