import React from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';

import Text from '@agile-actors/client/commons/components/text';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';

import ContactMap from '../contact-map';
import styles from './style';

type Props = {
  classes: Object,
  content: Object,
};

const ContactIntro = ({ classes, content }: Props) => (
  <Grid
    container
    direction="column"
    justify="center"
    className={classes.container}>
    <Text text={content.title} variant="h3" className={classes.label} />
    <Divider className={classes.divider} />
    <Text text={content.text} variant="subtitle1" className={classes.text} />
    <ContactMap content={content.map} />
  </Grid>
);

export default compose(
  withStyles(styles),
  withCmsContent('intro'),
)(ContactIntro);
