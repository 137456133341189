import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import { openModal, closeModal } from './actions';

const modalDefaultState = {
  footer: {
    open: false,
  },
  map: {
    open: false,
  },
  consent: {
    open: true,
  },
};

const modalActionsReducers = {
  [openModal.type]: (modal, { payload }) =>
    update(modal, {
      [payload.component]: { open: { $set: true } },
    }),
  [closeModal.type]: (modal, { payload }) =>
    update(modal, {
      [payload.component]: { open: { $set: false } },
    }),
};

const reducer = handleActions(modalActionsReducers, modalDefaultState);

export default reducer;
