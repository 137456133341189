import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import Text from '@agile-actors/client/commons/components/text';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';

import { ContentLayout } from 'components';

import styles from './style';

type Props = {
  classes: Object,
  content: Object,
};

const InformationSecurityPolicy = ({ classes, content }: Props) => (
  <ContentLayout styleOverides={classes}>
    <Text text={content.text} color="initial" />
    <Text
      text="Chief Executive Officer"
      color="initial"
      fullWidth
      center
      className={classes.ceo}
    />
  </ContentLayout>
);

export default compose(
  withStyles(styles),
  withCmsContent('informationSecurity'),
)(InformationSecurityPolicy);
