const styles = ({ typography }) => ({
  curveBackgroundWrapper: {
    position: 'relative',
    width: '100%',
    height: '35vw',
    minHeight: typography.pxToRem(200),
    maxHeight: typography.pxToRem(600),

    '& .slick-dots': {
      zIndex: 9,
      bottom: `${typography.pxToRem(50)} !important`,
      position: 'relative',
      opacity: 0,
      transition: 'opacity 0.3s ease-in-out',
    },
    '& .slick-dots li': {
      margin: `0 1vw !important`,
    },
    '& .slick-dots li button:before': {
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      fontSize: `${typography.pxToRem(150)} !important`,
      lineHeight: 'inherit',
      height: 'auto',
      width: 'auto',
      left: typography.pxToRem(-10),
    },
    '& .slick-arrow': {
      display: 'none !important',
    },
  },
  curve: {
    position: 'absolute',
    top: typography.pxToRem(-1),
    width: '100%',
    height: '20%',
    maxHeight: '20%',
    minHeight: '20%',
    zIndex: 9,
    backgroundSize: '110%',
    backgroundPositionY: 'top',

    '& > svg': {
      height: '100% !important',
    },
  },
  curvedImage: {
    width: '100%',
    height: '35vw',
    minHeight: typography.pxToRem(200),
    maxHeight: typography.pxToRem(600),
  },
  curvedImageFixed: {
    // position: 'fixed',
    bottom: 0,
    zIndex: -1,
  },
  isVisible: {
    '& .slick-dots': {
      opacity: 1,
    },
  },
});

export default styles;
