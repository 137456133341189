import { actionProp, stateProp } from '@agile-actors/client/commons/store';

import { consentGiven as consentGivenAction } from './actions';

const consentGiven = actionProp(dispatch => ({
  consentGiven: value => dispatch(consentGivenAction(value)),
}));

const open = stateProp(({ modal }) => ({
  open: modal?.consent?.open,
}));

const consent = stateProp(({ layout: { cookies = {} } }) => ({
  consent: cookies?.consent,
}));

export { consentGiven, consent, open };
