import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import { toggleSidebar, openSidebar, closeSidebar } from './actions';

const sidebarDefaultState = {
  open: false,
};

const sidebarActionsReducers = {
  [toggleSidebar.type]: sidebar =>
    update(sidebar, {
      open: { $apply: value => !value },
    }),
  [openSidebar.type]: sidebar =>
    update(sidebar, {
      open: { $set: true },
    }),
  [closeSidebar.type]: sidebar =>
    update(sidebar, {
      open: { $set: false },
    }),
};

const reducer = handleActions(sidebarActionsReducers, sidebarDefaultState);

export default reducer;
