import React from 'react';

import {
  Hero,
  Life,
  Achieve,
  CurveBackground,
  CurrentOpenings,
} from './components';

export const Careers = () => (
  <>
    <Hero />
    <Life />
    <Achieve />
    <CurveBackground />
    <CurrentOpenings />
  </>
);

export default Careers;
