import React from 'react';

import { Hero, Leadership, CurveBackground, GetInTouch } from './components';

export const OurPeople = () => (
  <>
    <Hero />
    <Leadership />
    <CurveBackground />
    <GetInTouch />
  </>
);

export default OurPeople;
