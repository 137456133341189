import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './style';

type Props = {
  classes: Object,
};

const RegistrationNumber = ({ classes }: Props) => (
  <Typography variant="caption" color="initial" className={classes.label}>
    Company Registration Number: 145138401000
  </Typography>
);

export default withStyles(styles)(RegistrationNumber);
