import React from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';

import { withTabsIndex } from '@agile-actors/client/commons/components/withTabsIndex';

import TabLabel from './TabLabel';
import TabContent from './TabContent';

type Props = {
  classes: Object,
  color: string,
  styleOverides?: Object,
  content: [],
  tabIndex: number,

  onChangeTab: () => void,
};

const MobileContent = ({
  classes,
  color,
  styleOverides,
  content,
  onChangeTab,
  tabIndex,
}: Props) => (
  <Grid
    container
    direction="column"
    alignItems="flex-start"
    justify="center"
    className={classNames(classes.tabs, styleOverides.tabs)}>
    <TabLabel
      content={content}
      classes={classes}
      styleOverides={styleOverides}
      color={color}
      tabIndex={tabIndex}
      onChangeTab={onChangeTab}
    />
    <TabContent
      content={content}
      classes={classes}
      styleOverides={styleOverides}
      tabIndex={tabIndex}
    />
  </Grid>
);

MobileContent.defaultProps = {
  styleOverides: {},
};

export default withTabsIndex(MobileContent);
