import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { compose } from '@agile-actors/client/commons/utils';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';

import { Banner } from 'components';

import styles from './style';

type Props = {
  classes: Object,
  content: Object,
};

const Hero = ({ classes, content }: Props) => (
  <Banner hero content={content} styleOverides={classes} />
);

export default compose(
  withStyles(styles, { index: 1 }),
  withCmsContent('hero'),
)(Hero);
