const styles = ({ breakpoints, content, typography }) => ({
  container: {
    background: 'linear-gradient(90deg, #70C1C9 0%, #86CDD4 100%)',
    width: '90%',
    borderRadius: typography.pxToRem(30),
    boxShadow: `0 ${typography.pxToRem(20)} ${typography.pxToRem(30)}
                0 rgba(0,0,0,0.12)`,
    zIndex: 2,
    ...content.calc('margin', 'top', '*', '-1.7'),
    position: 'relative',
  },
  title: {
    fontWeight: 400,
    ...content.calc('margin', 'bottom', '/', '2'),
  },
  text: {
    lineHeight: 1.7,
    ...content.calc('margin', 'bottom', '/', '2'),
  },
  button: {
    fontWeight: 500,

    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.2)',
    },
  },
  background: {
    display: 'none',
    height: '21vw',
    width: '28vw',
    left: '70%',
    top: typography.pxToRem(340),

    '& img:': {
      objectFit: 'cover !important',
    },

    [breakpoints.up('lg')]: { display: 'block' },
  },
  [breakpoints.up('sm')]: {
    container: {
      width: '75%',
      ...content.calc('margin', 'top', '*', '-1.1'),
      marginBottom: 0,
    },
  },
  [breakpoints.up('lg')]: {
    container: {
      ...content.calc('margin', 'top', '*', '-0.7'),
      ...content.calc('margin', 'bottom', '*', '2'),
    },
    content: {
      paddingLeft: '16%',
      paddingRight: '16%',
    },
  },
});

export default styles;
