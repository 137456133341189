import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { v4 } from 'uuid';

import { Link } from '@agile-actors/client/commons/components/router';
import Text from '@agile-actors/client/commons/components/text';
import { withModelProps } from '@agile-actors/client/commons/components/model-props';
import { compose } from '@agile-actors/client/commons/utils';

import { Modal } from 'components';
import { openModal, closeModal } from 'components/modal/ui-model';

import { open } from './ui-model';
import content from './content';
import styles from './style';

type Props = {
  classes: Object,
  closeModal: (modalState) => void,
  openModal: (modalState) => void,
  open: boolean,
};

const modalContainer = { component: 'footer' };

const Privacy = ({ classes, closeModal, openModal, open }: Props) => (
  <div>
    <Text
      onClick={() => {
        openModal(modalContainer);
      }}
      role="link"
      onKeyPress={() => {
        openModal(modalContainer);
      }}
      text={content.link}
      color="initial"
      className={classes.mainLink}
    />
    <Modal className={classes.modal} open={open} container={modalContainer}>
      <List className={classes.list}>
        {content.links.map((item) => (
          <ListItem key={v4()}>
            <ListItemIcon>
              <ChevronRight />
            </ListItemIcon>
            <Link to={item.url} className={classes.privacyLink}>
              <Text
                text={item.title}
                color="initial"
                onClick={() => {
                  closeModal(modalContainer);
                }}
                className={classes.privacyLink}
              />
            </Link>
          </ListItem>
        ))}
      </List>
    </Modal>
  </div>
);

export default compose(
  withModelProps({ open, openModal, closeModal }),
  withStyles(styles),
)(Privacy);
