import React from 'react';

import { Hero, Industries, Models, GetInTouch } from './components';

export const Home = () => (
  <>
    <Hero />
    <Models />
    <Industries />
    <GetInTouch />
  </>
);

export default Home;
