import React from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import Text from '@agile-actors/client/commons/components/text';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';

import { ContentLayout } from 'components';

import styles from './style';

type Props = {
  classes: Object,
  content: Object,
};

const Intro = ({ classes, content }: Props) => (
  <ContentLayout styleOverides={classes}>
    <Text
      text={content.title}
      variant="h4"
      color="initial"
      className={classes.title}
    />
    <Grid item xs={12} sm={6} className={classes.countryContainer}>
      <Text
        text={content.greece.title}
        variant="h5"
        color="initial"
        className={classes.countryTitle}
      />
      {content.greece.data.map((text) => (
        <Grid container alignItems="flex-start" spacing={0} key={text.id}>
          <Grid item xs={3}>
            <Text text={text.label} type="body1" />
          </Grid>
          <Grid item xs={9}>
            <Text text={text.details} type="body2" />
          </Grid>
        </Grid>
      ))}
    </Grid>
  </ContentLayout>
);

export default compose(withStyles(styles), withCmsContent('intro'))(Intro);
