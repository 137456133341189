import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import Signature from '@agile-actors/client/commons/components/signature';

import styles from './style';

type Props = {
  classes: Object,
};

const Copyright = ({ classes }: Props) => (
  <Signature
    company="AgileActors"
    color="initial"
    className={classes.copyright}
  />
);

export default withStyles(styles)(Copyright);
