import React from 'react';
import Hidden from '@material-ui/core/Hidden';

import Image from '@agile-actors/client/commons/components/image';

type Props = {
  content: string,
  className: string,
};

const WatermarkImage = ({ content, className }: Props) => (
  <div style={{ width: '100%', height: '100%' }}>
    <Hidden xsDown>
      <Image content={content} className={className} />
    </Hidden>
    <Hidden smUp>
      <Image background content={content} className={className} />
    </Hidden>
  </div>
);

export default WatermarkImage;
