import React from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';

import Text from '@agile-actors/client/commons/components/text';
import Button from '@agile-actors/client/commons/components/button';

import { compose } from '@agile-actors/client/commons/utils';
import { ContentLayout } from 'components';

import { NormalContent, SliderContent } from './components';
import styles from './style';

type Props = {
  content: Object,
  classes: Object,
  width: string,
  border?: boolean,
  slider?: boolean,
  color?: string,
  styleOverides?: Object,
  backgroundColor?: string,
};

const Cards = ({
  content,
  border,
  slider,
  classes,
  width,
  color,
  styleOverides,
  backgroundColor,
  ...rest
}: Props) => {
  const oddCards = content.cards.length % 2 !== 0;
  const showSlider =
    slider &&
    ((oddCards && width !== 'lg' && width !== 'xl') ||
      (!oddCards && width === 'xs'));

  if (content.cards.length > 4) {
    console.warn('*** WARNING: Cards Template - Max cards allowed, is 4...');
  }

  return (
    <ContentLayout
      backgroundColor={backgroundColor}
      styleOverides={styleOverides}>
      <Grid container direction="column" justify="center" alignItems="center">
        <Text
          text={content.title}
          variant="h3"
          color={color || 'initial'}
          align="center"
          className={classNames(classes.outerTitle, styleOverides.outerTitle)}
        />
        <Text
          text={content.subtitle}
          variant="h5"
          color={color || 'initial'}
          align="center"
          className={classNames(
            classes.outerSubtitle,
            styleOverides.outerSubtitle,
          )}
        />

        {showSlider ? (
          <SliderContent
            content={content.cards}
            classes={classes}
            styleOverides={styleOverides}
            border={border}
            {...rest}
          />
        ) : (
          <NormalContent
            content={content.cards}
            classes={classes}
            styleOverides={styleOverides}
            width={width}
            border={border}
            {...rest}
          />
        )}

        <Button
          size="large"
          color={color || 'primary'}
          center
          className={classNames(
            classes.button,
            slider && classes.buttonSlider,
            styleOverides.button,
          )}
          label={content.buttonLabel}
          to={content.buttonLink}
          labelClassName={classNames(
            classes.buttonLabel,
            styleOverides.buttonLabel,
          )}
        />
      </Grid>
    </ContentLayout>
  );
};

Cards.defaultProps = {
  styleOverides: {},
  border: false,
  slider: false,
  color: '',
  backgroundColor: '',
};

export default compose(withStyles(styles), withWidth())(Cards);
