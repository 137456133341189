import React from 'react';

import { withCmsContent } from '@agile-actors/client/commons/components/cms';

import { Tabs } from 'components';

type Props = {
  content: Object,
};

const OurPractices = ({ content }: Props) => (
  <Tabs backgroundColor="#f4f4f4" content={content} />
);

export default withCmsContent('practices')(OurPractices);
