const styles = ({ typography, palette, breakpoints }) => ({
  cardContainer: {
    width: 'auto',
  },
  cardWrapper: {
    height: 'auto',
    width: 'auto',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    maxWidth: typography.pxToRem(430),
    minWidth: typography.pxToRem(200),
    margin: `0 ${typography.pxToRem(10)} ${typography.pxToRem(20)}`,
  },
  withBorder: {
    '& > $cardWrapper': {
      boxShadow: `
      0
      ${typography.pxToRem(20)}
      ${typography.pxToRem(30)}
      0
      rgba(36, 50, 66, 0.14)
    `,
      borderRadius: typography.pxToRem(4),
      overflow: 'hidden',
      zIndex: 1,
    },

    '& $card': {
      borderStyle: 'solid',
      borderImage: 'linear-gradient(to right, #3399FF, #00D9EF) 90% 1',
      borderWidth: `${typography.pxToRem(4)} 0 0 0`,
    },
  },
  slider: {
    '& > $cardWrapper': {
      maxWidth: typography.pxToRem(430),
      height: '100%',
    },

    '&$withBorder > $cardWrapper': {
      maxWidth: typography.pxToRem(290),
      marginBottom: typography.pxToRem(35),

      [breakpoints.up('400')]: {
        maxWidth: typography.pxToRem(340),
      },

      [breakpoints.up('sm')]: {
        maxWidth: typography.pxToRem(430),
      },
    },
  },
  fullWidth: {
    '& > $cardWrapper': {
      margin: 0,
      width: '100% !important',
      maxWidth: 'none',
    },
  },
  card: {
    position: 'relative',
    minHeight: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'visible',
  },
  cardButton: {
    margin: `${typography.pxToRem(30)} auto`,
    paddingTop: typography.pxToRem(15),
    paddingBottom: typography.pxToRem(15),
  },
  image: {
    width: '100%',
    height: typography.pxToRem(155),
  },
  textWrapper: {
    padding: `${typography.pxToRem(30)} !important`,

    [breakpoints.up('lg')]: {
      padding: `${typography.pxToRem(30)} ${typography.pxToRem(
        20,
      )}  !important`,
    },
    [breakpoints.up('xl')]: {
      padding: `${typography.pxToRem(30)} !important`,
    },
  },
  title: {
    marginTop: typography.pxToRem(10),
  },
  text: {
    marginTop: typography.pxToRem(30),
  },
  divider: {
    width: '100%',
  },
  listWrapper: {
    position: 'relative',
    marginTop: typography.pxToRem(30),
    maxWidth: typography.pxToRem(600),

    '&:after': {
      position: 'absolute',
      content: '""',
      width: '100%',
      backgroundColor: palette.common.white,
      height: typography.pxToRem(1),
      top: typography.pxToRem(-31),
      left: 0,
    },
  },
  listTitle: {
    marginBottom: typography.pxToRem(10),
  },
  listItem: {
    margin: `0 0 ${typography.pxToRem(10)}`,
    padding: 0,

    '& > div': {
      minWidth: 30,
    },

    '&:last-child': {
      marginBottom: 0,
    },
  },
  tick: {
    color: palette.secondary.main,
    width: typography.pxToRem(16),
  },
});

export default styles;
