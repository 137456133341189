const styles = ({ typography, breakpoints }) => ({
  content: {
    minHeight: typography.pxToRem(400),
    paddingBottom: 0,
  },
  image: {
    width: '100%',

    [breakpoints.up('sm')]: {
      width: '70%',
    },
    [breakpoints.up('lg')]: {
      width: typography.pxToRem(500),
      height: typography.pxToRem(375),

      '& img': {
        objectFit: 'contain !important',
      },
    },
  },
});

export default styles;
