const typography = typography => ({
  fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
  fontSize: 16,
  h1: {
    fontFamily: 'chalet-new-york-sixty, sans-serif',
    fontSize: typography.pxToRem(80),
    lineHeight: 1.2,
    fontWeight: 700,
  },
  h2: {
    fontFamily: 'chalet-new-york-sixty, sans-serif',
    fontSize: typography.pxToRem(60),
    lineHeight: 1.2,
    fontWeight: 700,
  },
  h3: {
    fontFamily: 'chalet-new-york-sixty, sans-serif',
    fontSize: typography.pxToRem(50),
    lineHeight: 1.2,
    fontWeight: 500,
  },
  h4: {
    fontFamily: 'chalet-new-york-sixty, sans-serif',
    fontSize: typography.pxToRem(30),
    lineHeight: 1.5,
  },
  h5: {
    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    fontSize: typography.pxToRem(22),
    lineHeight: 1.5,
  },
  h6: {
    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    fontSize: typography.pxToRem(20),
    lineHeight: 1.5,
    fontWeight: 400,
  },
  subtitle1: {
    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    fontSize: typography.pxToRem(18),
    lineHeight: 1.5,
    fontWeight: 300,
  },
  body1: {
    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    fontSize: typography.pxToRem(16),
    lineHeight: 1.5,
    fontWeight: 400,
  },
  body2: {
    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    fontSize: typography.pxToRem(14),
    lineHeight: 1.5,
  },
  caption: {
    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    fontSize: typography.pxToRem(12),
    lineHeight: 1.2,
  },
  button: {
    fontFamily: 'chalet-new-york-sixty, sans-serif',
    fontSize: typography.pxToRem(20),
    fontWeight: 500,
    textTransform: 'none',
  },
});

export default typography;
