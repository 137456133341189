import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';

import Media from '@agile-actors/client/commons/components/media';
import Text from '@agile-actors/client/commons/components/text';
import Button from '@agile-actors/client/commons/components/button';
import { compose } from '@agile-actors/client/commons/utils';

import { ContentLayout } from 'components';

import styles from './style';

type Props = {
  classes: Object,
  width: string,
  styleOverides?: Object,
  content: Object,
  color?: string,
  buttonColor?: string,
  backgroundColor?: string,
  hero?: boolean,
  backgroundOnly?: boolean,
  centerText?: boolean,
  centerButton?: boolean,
  setBackgroundSize?: boolean,
};

const Banner = ({
  classes,
  width,
  styleOverides = {},
  content,
  color = '',
  buttonColor = '',
  backgroundColor = '',
  hero = false,
  backgroundOnly = false,
  centerText = false,
  centerButton = false,
  setBackgroundSize = false,
  ...rest
}: Props) => {
  // This values could be moved to the CMS if new asset added and values cannot globally apply
  const backgroundSize = {
    xs: {
      step: 300,
      maxWidth: 600,
    },
    sm: {
      step: 600,
      maxWidth: 1280,
    },
    md: {
      step: 1280,
      maxWidth: 1280,
    },
  };

  const backgroundProps =
    setBackgroundSize && Object.keys(backgroundSize).includes(width)
      ? backgroundSize[width]
      : {};

  return (
    <ContentLayout
      backgroundColor={backgroundColor}
      styleOverides={styleOverides}
      backgroundOnly={backgroundOnly}>
      {content.background && (
        <Media
          background
          content={content.background}
          backgroundColor={backgroundColor}
          className={classNames(
            classes.backgroundImg,
            styleOverides.backgroundImg,
          )}
          {...backgroundProps}
          {...rest}
        />
      )}
      <Grid
        container
        direction="column"
        alignItems={centerText ? 'center' : 'flex-start'}
        justify="center">
        {hero && <div className={classes.spacer} />}
        {hero &&
          (width === 'xs' || width === 'sm' || !content?.background?.video) && (
            <div className={classes.spacer} />
          )}
        <Text
          text={content.title}
          variant={hero ? 'h1' : 'h3'}
          color={color || 'textSecondary'}
          align={centerText ? 'center' : 'left'}
          className={classNames(classes.title, styleOverides.title)}
        />
        <Text
          text={content.subtitle || ''}
          variant={hero ? 'h4' : 'h5'}
          color={color || 'textSecondary'}
          align={centerText ? 'center' : 'left'}
          className={classNames(classes.subtitle, styleOverides.subtitle)}
        />
        {hero && content.text ? <div className={classes.line} /> : null}
        <Text
          text={content.text}
          variant={hero ? 'h6' : 'body1'}
          color={color || 'textSecondary'}
          align={centerText ? 'center' : 'left'}
          className={classNames(classes.text, styleOverides.text)}
        />
        <Button
          size="large"
          className={classNames(classes.button, styleOverides.button)}
          color={buttonColor || 'default'}
          center={centerButton}
          label={content.buttonLabel}
          to={content.buttonLink}
          labelClassName={classNames(
            classes.buttonLabel,
            styleOverides.buttonLabel,
          )}
        />
        {hero &&
          (width === 'xs' || width === 'sm' || !content.background.video) && (
            <div className={classes.spacer} />
          )}
      </Grid>
    </ContentLayout>
  );
};

Banner.defaultProps = {
  styleOverides: {},
  color: '',
  buttonColor: '',
  backgroundColor: '',
  hero: false,
  centerText: false,
  backgroundOnly: false,
  centerButton: false,
  setBackgroundSize: false,
};

export default compose(withStyles(styles), withWidth())(Banner);
