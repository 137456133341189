const styles = ({ typography, palette, breakpoints }) => ({
  image: {
    height: typography.pxToRem(300),

    '& img': {
      objectFit: 'cover !important',
    },
  },
  outerSubtitle: {
    maxWidth: typography.pxToRem(700),
  },
  text: {
    '& a': {
      color: palette.primary.main,
      fontSize: typography.pxToRem(12),

      [breakpoints.up(1410)]: {
        fontSize: typography.pxToRem(14),
      },
    },
  },
  cardWrapper: {
    paddingBottom: typography.pxToRem(60),
  },
  cardButton: {
    position: 'absolute',
    bottom: typography.pxToRem(-58),
    left: '50%',
    transform: 'translateX(-50%)',
    whiteSpace: 'nowrap',
  },
  divider: {
    display: 'none',
  },
});

export default styles;
