import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';

import Text from '@agile-actors/client/commons/components/text';
import Button from '@agile-actors/client/commons/components/button';
import Image from '@agile-actors/client/commons/components/image';
import { withCmsContent } from '@agile-actors/client/commons/components/cms';
import { compose } from '@agile-actors/client/commons/utils';

import { ContentLayout, ContentImage } from 'components';

import styles from './style';

type Props = {
  classes: Object,
  content: Object,
  width: string,
};

const AgileMethodology = ({ classes, content, width }: Props) => (
  <ContentLayout>
    <Text
      text={content.title}
      variant="h3"
      align="center"
      className={classes.title}
    />

    <Grid container justify="center" className={classes.methodologiesContainer}>
      <Hidden mdDown>
        <Image
          background
          step={1690}
          size={1690}
          maxWidth={1690}
          content={content.background}
        />
      </Hidden>

      <div className={classes.methodologiesWrapper}>
        {content.methodologies.map((item, index) => (
          <div key={item.id} className={classes.methodologiesItem}>
            <ContentImage
              imageOntop
              reverse={index % 2 !== 0}
              image={item.image}
              styleOverides={classes}>
              <Grid container justify="center">
                <Hidden mdDown>
                  <Grid item lg={2}>
                    <Text
                      variant="h1"
                      text={`${index + 1}`}
                      className={classes.orderTitle}
                    />
                  </Grid>
                </Hidden>

                <Grid item xs={12} lg={10} >
                  <Text
                    text={item.title}
                    variant="h4"
                    color="initial"
                    align={width !== 'lg' && width !== 'xl' ? 'center' : 'left'}
                    className={classes.contentTitle}
                  />
                  <Text
                    text={item.text}
                    variant="h5"
                    color="initial"
                    align={width !== 'lg' && width !== 'xl' ? 'center' : 'left'}
                    className={classes.contentText}
                  />
                </Grid>
              </Grid>
            </ContentImage>
          </div>
        ))}
      </div>
    </Grid>

    <Button
      color="primary"
      center
      label={content.buttonLabel}
      to={content.buttonLink}
      className={classes.button}
    />
  </ContentLayout>
);

export default compose(
  withWidth(),
  withCmsContent('methodology'),
  withStyles(styles),
)(AgileMethodology);
