import React from 'react';
import withWidth from '@material-ui/core/withWidth';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';

import { Card } from 'components';

type Props = {
  cardsRow: [Object],
  border?: boolean,
  singleRow?: boolean,
  classes: Object,
  width: string,
  styleOverides?: Object,
};

const CardRow = ({
  singleRow,
  cardsRow,
  border,
  classes,
  width,
  styleOverides = {},
  ...rest
}: Props) => (
  <Grid
    container
    justify="center"
    direction={singleRow && width !== 'lg' && width !== 'xl' ? 'column' : 'row'}
    className={classNames(
      classes.cardsRow,
      singleRow && classes.cardsSingleRow,
      styleOverides.cardsRow,
      singleRow && styleOverides.cardsSingleRow,
    )}>
    {cardsRow.map(card => (
      <Card
        center
        content={card}
        styleOverides={styleOverides}
        key={`${card.id}`}
        border={border}
        {...rest}
      />
    ))}
  </Grid>
);

CardRow.defaultProps = {
  styleOverides: {},
  border: false,
  singleRow: false,
};

export default withWidth()(CardRow);
