const styles = ({ typography, palette, content, breakpoints }) => ({
  panesWrapper: {
    height: '100%',
  },
  contentPane: {
    [breakpoints.up('lg')]: {
      padding: typography.pxToRem(20),
    },
  },
  imagePane: {
    height: '60vw',
    ...content.padding('top', 'left', 'right'),

    '& .slick-dots': {
      bottom: typography.pxToRem(5),
    },

    '& .slick-dots button:before': {
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      color: `${palette.common.white} !important`,
      opacity: 0.5,
    },

    [breakpoints.up('lg')]: {
      height: '100%',
      padding: typography.pxToRem(40),
    },
  },
  imagePaneWatermark: {
    paddingBottom: 0,
  },
  image: {
    margin: '0 auto',

    [breakpoints.up('lg')]: {
      margin: '0 0 0 auto',
    },
  },
  imageReverse: {
    [breakpoints.up('lg')]: {
      margin: '0 auto 0 0',
    },
  },
  imageBackground: {
    height: '100%',
    opacity: 0.2,
    backgroundOrigin: 'content-box',
    ...content.padding(),
  },
});

export default styles;
